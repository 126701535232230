import { Button, Col, Form, Input, PageHeader, Row, Spin } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SelectUserProfile } from './components/SelectProfile';
import FormTechnician from './components/FormTechnician';
import FormInterested from './components/FormInterested';
import FormOperator from './components/FormOperator';
import { UsertypeDescription } from './components/UsertypeDescription';
import { UsernameDescription } from './components/UsernameDescription';
import { PasswordInput } from './components/PasswordInput';
import { Cards } from '../../components/cards/frame/cards-frame';
import { FormWrapperPage } from '../../components/formWrapper/form-wrapper';
import { ProfileTypes } from '../../config/constants/userConstants';
import { Main, TitleForm } from '../../container/styled';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { useNotifications } from '../../hooks/useNotifications';
import useUser from '../users/hook/useUser';

export const UserDetails = () => {
  const [form] = Form.useForm();
  const [userType, setUserType] = useState(null);
  const [username, setUsername] = useState('');
  const [switchSkills, setSwitchSwills] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const [selectedHeadquarters, setSelectedHeadquarters] = useState([]);
  const [viewAllServices, setViewAllServices] = useState(false);
  const { userId } = useParams();
  const { translate: t } = useDictionaryTranslation();
  const { updateUser, userData, createUser, hasError, isLoading } = useUser({ userId });
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const prefix = useSelector((state) => state.auth.accountData?.prefix);
  const userCreating = userId == null;

  const onFieldsChange = useCallback((_, fields) => {
    const hasErrors = fields.some(({ errors }) => errors.length > 0);
    setFormHasError(hasErrors);
  }, []);

  const onHandleChange = (event) => {
    setUserType(event?.value);
  };

  const onHandleSkills = (event) => {
    setSwitchSwills(event);
  };

  const getProfilesFields = () => {
    const profileComponents = {
      [ProfileTypes.TECHNICIAN.id]: () => {
        return <FormTechnician onHandleSkills={onHandleSkills} switchSkills={switchSkills} userData={userData} />;
      },
      [ProfileTypes.INTERESTED.id]: () => {
        return <FormInterested />;
      },
      [ProfileTypes.OPERATOR.id]: () => {
        return (
          <FormOperator
            headquarters={selectedHeadquarters}
            viewAllServices={viewAllServices}
            onChangeHeadquarters={setSelectedHeadquarters}
            onChangeViewAllServices={setViewAllServices}
          />
        );
      },
    };
    return profileComponents[userType]?.();
  };

  const onSubmit = async () => {
    const values = form.getFieldValue();
    const data = {
      canCreatePetitions: values.canCreatePetitions,
      username: values.username?.replace(/\W/g, ''),
      email: values.emails,
      password: values.password,
      identification: values.identification,
      name: values.name,
      idProfile: values.userType.value,
      cellular: values.phone,
      assignedCompany: values.assignedCompany,
      canViewServicesHistory: values.allowToViewHistory,
      enableAvailabilitySchedule: values.enableSchedulingTimes,
      canCreateServices: values.createServicesFromMobile,
      administrateAdvancedAvailability: values.administrateAdvancedAvailability,
      phone: values.phone,
      schedulingTimes: values.schedulingTimes,
      scheduleLocks: values.scheduleLocks,
      idResourcesToAdmin: values.resources?.map((resource) => resource.value),
      editInfoCustomers: values.editInfoCustomers,
      administrateServices: values.administrateServices,
      seeAllServices: values.seeAllServices,
      servicesTypesAssigned: values.servicesTypesAssigned?.map((option) => option.value),
      administrateTask: values.administrateTask,
      seeAllTask: values.seeAllTask,
      administrateWorkflows: values.administrateWorkflows,
      seeAllWorkflows: values.seeAllWorkflows,
      administrateClients: values.administrateClients,
      specialities: values.skills.map((skill) => skill.value),
      companies: values.assignedCompanies.map((company) => company.value),
      idsHeadquarters: values.headquarters.map((headquarter) => headquarter.value),
      canChangeServiceStatus: values.canChangeServiceStatus,
    };
    try {
      if (userId != null) {
        await updateUser(data);
        showSuccessNotification({
          title: 'success',
          description: 'successUpdatingRecord',
        });
      } else {
        await createUser(data);
        showSuccessNotification({
          title: 'success',
          description: 'successCreatingRecord',
        });
      }
      setSuccessCreate(true);
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: userId == null ? 'errorCreatingRecord' : 'errorUpdatingRecord',
      });
    }
  };

  const onHandleUsername = (event) => {
    setUsername(event.target.value?.replace(/\W/g, ''));
  };

  useEffect(() => {
    const unPrefixUsername = userData.username?.replace(`${prefix}.`, '');
    const getItems = ({ items, valueKey = '_id', labelKey = 'name' }) => {
      if (!Array.isArray(items)) return [];
      return items.map((item) => ({
        value: item?.[valueKey],
        label: item?.[labelKey],
      }));
    };

    const headquarters = getItems({ items: userData.profile?.idsHeadquarters });
    const formData = {
      userType: {
        value: userData.profile?.idProfile,
      },
      schedulingTimes: userData.schedulingTimes,
      scheduleLocks: userData.scheduleLocks,
      skills: getItems({ items: userData.profile?.specialities }),
      username: unPrefixUsername ?? '',
      emails: userData.emails?.[0].address,
      phone: userData.profile?.phone,
      name: userData.profile?.name,
      identification: userData.profile?.identification,
      assignedCompany: userData.profile?.assignedCompany,
      allowToViewHistory: Boolean(userData.profile?.canViewServicesHistory),
      enableSchedulingTimes: Boolean(userData.profile?.enableAvailabilitySchedule),
      administrateAdvancedAvailability: Boolean(userData.profile.administrateAdvancedAvailability),
      headquarters,
      editInfoCustomers: Boolean(userData.profile?.editInfoCustomers),
      administrateServices: Boolean(userData.profile?.administrateServices),
      seeAllServices: Boolean(userData.profile?.seeAllServices),
      servicesTypesAssigned: getItems({ items: userData.servicesTypesAssigned }),
      seeAllTask: Boolean(userData.profile?.seeAllTask),
      administrateTask: Boolean(userData.profile?.administrateTask),
      administrateWorkflows: Boolean(userData.profile?.administrateWorkflows),
      seeAllWorkflows: Boolean(userData.profile?.seeAllWorkflows),
      assignedCompanies: getItems({ items: userData.profile?.companies }),
      createServicesFromMobile: Boolean(userData.profile?.canCreateServices),
      administrateClients: Boolean(userData.profile?.administrateClients),
      resources: userData?.resourcesToAdmin?.map((item) => ({ value: item._id, label: item.profile.name })),
      password: '',
      canChangeServiceStatus: Boolean(userData.profile?.canChangeServiceStatus),
    };
    form.setFieldsValue(formData);
    setViewAllServices(userData.profile.seeAllServices);
    setUserType(userData.profile?.idProfile);
    setUsername(unPrefixUsername ?? '');
    setSwitchSwills(userData.profile?.enableAvailabilitySchedule);
    setSelectedHeadquarters(headquarters);
  }, [userData, form]);

  if (successCreate || hasError) return <Redirect to="/settings/users" />;

  return (
    <>
      <PageHeader title={t(userId != null ? t('editUser') : t('createUser'))} ghost />

      <Main>
        <Cards headless>
          <div style={{ marginBottom: '30px' }}>
            <TitleForm>{t('userTypeConfigBase')}</TitleForm>
            <h4>{t('configUserMessage')}</h4>
          </div>
          <Spin spinning={isLoading}>
            <FormWrapperPage>
              <Form
                layout="vertical"
                name="createUserForm"
                onFinish={onSubmit}
                form={form}
                autoComplete="off"
                scrollToFirstError
                onFieldsChange={onFieldsChange}
              >
                <Row>
                  <Col xs={24} md={12}>
                    {userData.profile?.idProfile !== '1' ? (
                      <Form.Item
                        name="userType"
                        label={t('userType')}
                        rules={[{ required: true, message: t('userTypeRequired') }]}
                      >
                        <SelectUserProfile onChange={onHandleChange} disabled={userId != null} />
                      </Form.Item>
                    ) : null}
                  </Col>
                  <Col xs={24} md={12}>
                    <UsertypeDescription userType={userType} />
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="username"
                      label={t('username')}
                      rules={[{ required: true, message: t('usernameRequired') }]}
                    >
                      <Input onChange={onHandleUsername} disabled={userId != null || !userType} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <UsernameDescription username={username} userType={userType} />
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item name="name" label={t('name')} rules={[{ required: true, message: t('nameRequired') }]}>
                      <Input disabled={!userType} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="identification"
                      label={t('identification')}
                      rules={[{ required: true, message: t('identificationRequired') }]}
                    >
                      <Input disabled={!userType} />
                    </Form.Item>
                  </Col>

                  {userId ? null : (
                    <>
                      <Col xs={24} md={12}>
                        <PasswordInput
                          name="password"
                          label={t('passwordLabel')}
                          required={userCreating}
                          disabled={!userType}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="passwordConfirmation"
                          label={t('confirmPassword')}
                          rules={[
                            { required: userCreating, message: t('passwordRequiredMessage') },
                            ({ getFieldValue }) => ({
                              message: t('passwordAndConfirmationsMustBeEquals'),
                              validator: (_, value) => {
                                const currentPassword = getFieldValue('password') ?? '';
                                const currentValue = value ?? '';
                                if (currentValue !== currentPassword) return Promise.reject();
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password disabled={!userType} />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="emails"
                      label={t('email')}
                      rules={[{ required: true, type: 'email', message: t('emailRequired') }]}
                    >
                      <Input disabled={!userType} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="phone"
                      label={t('mobilePhone')}
                      rules={[{ required: true, message: t('mobilePhoneRequired') }]}
                    >
                      <Input disabled={!userType} />
                    </Form.Item>
                  </Col>
                  {getProfilesFields()}
                </Row>

                <Row justify="end">
                  <Button form="createUserForm" htmlType="submit" type="primary" disabled={formHasError}>
                    {t('save')}
                  </Button>
                </Row>
              </Form>
            </FormWrapperPage>
          </Spin>
        </Cards>
      </Main>
    </>
  );
};
