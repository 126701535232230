export const TRANSLATIONS_ES = {
  accept: "Aceptar",
  accepted: "Aceptado",
  acceptedBy: "Aceptado por:",
  acceptedDateOverview: "Fecha de aceptación: ",
  acceptedService: "Aceptación de {{dictionary.commonTerms.service}}",
  account: "Cuenta",
  accountType: "Tipo de cuenta",
  accounts: "Cuentas",
  actions: "Acciones",
  activateExtendForm: "Habilitar Formulario Extendido",
  activeChats: "Activos",
  add: "Agregar",
  addBlockedDays: "Agregar días para bloqueo",
  addItem: "Agregar ítem",
  addListItem: "Agregar item",
  addPatientList: "Agregar {{dictionary.commonTerms.finalCustomer}} a la lista de espera",
  addService: "Agregar",
  addVariable: "+Agregar",
  address: "Dirección",
  addressIsRequired: "La dirección es obligatoria",
  addressPlaceholder: "Ingrese una dirección",
  addressRequired: "La dirección es obligatoria",
  admin: "{{dictionary.profileNames.admin}}",
  administrateServices: "Permite asignar {{dictionary.commonTerms.servicePlural}}",
  administrateTask: "Permite ver el menú de Tareas",
  administrateWorkflows: "Permite ver el menú de Flujos de trabajo",
  administration: "Administración",
  advancedAvailability: "Disponibilidad avanzada",
  alertResolvedBy: "Alerta resuelta por: ",
  alertResolvedDate: "Fecha de resolución de alerta: ",
  allPatients: "Todos los {{dictionary.commonTerms.finalCustomerPlural}}",
  allowCreation: "Crearás esta {{dictionary.commonTerms.service}} sin haber asignado ningún {{dictionary.profileNames.technician}}",
  allowToViewHistory: "Permitir ver el historial",
  allowedIncidetnTypes: "Tipos de {{dictionary.commonTerms.servicePlural}} permitidos",
  answerDate: "Fecha De Respuesta",
  api: "API",
  apiAdminOptions: "Habilitar API (opciones de administración)",
  apiCreate: "+ Crear nuevo Api key",
  apiMessage: "Este es tu Api key, Recuerda que vence el",
  apiMessageWhatsapp: "Este es tu código o key. Usa este para colocarlo en la configuración de Meta",
  appColorMobile: "Colores app móvil",
  appColorText: "Selecciona un esquema de colores, éstos serán usados en algunas secciones o botones de la aplicación móvil.",
  appPlaceholder: "Seleccione un formulario móvil",
  apply: "Aplicar",
  appoinmentNoAvailable: "No hay {{dictionary.commonTerms.servicePlural}} disponibles bajo estos criterios de búsqueda, seleccione otras opciones y busque nuevamente.",
  appointment: "citas",
  appointmentAssignation: "Asignación de {{dictionary.commonTerms.service}}",
  appointmentAssignmentMessage: "Está intentando asignar las siguientes {{dictionary.commonTerms.servicePlural}} al {{dictionary.commonTerms.finalCustomer}}",
  appointmentAvailable: "Citas Disponibles",
  appointmentCancelation: "Cancelación de {{dictionary.commonTerms.service}}",
  appointmentNotification: "Me gustaria recibir notificaciones en caso de que se pueda adelantar mi cita y autorizo a que me contacten a mi numero de celular",
  appointmentOpportunitiesReport: "Reporte de oportunidades de {{dictionary.commonTerms.servicePlural}}",
  appointmentRepeat: "Repetir esta {{dictionary.commonTerms.service}}",
  appointmentScheduledSuccessfully: "Tu cita se ha agendado exitosamente",
  appointmentScheduledToDate: "para el {{date}} a las {{time}}",
  appointmentsOf: " Citas de ",
  areYouSure: "¿Está seguro?",
  assetHasOverlaps: "{{asset}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario",
  assetNotAvailable: "{{dictionary.commonTerms.asset}} no disponible en este horario",
  assetPlaceholder: "Seleccione {{dictionary.commonTerms.asset}}",
  assetRequired: "Se requiere seleccionar un Activo al asignar este tipo de {{dictionary.commonTerms.servicePlural}}",
  assetTypes: "Activos",
  assets: "Activos",
  assetsDescription: "Crea listados de Activos que funcionan como bases de datos que pueden ser usados en los formularios, creación de {{dictionary.commonTerms.service}}, entre otros.",
  assetsGroup: "Selecciona el grupo de Activos que corresponde a este tipo de Cita",
  assigmentAppoinment: "Agendamiento de",
  assign: "Asignar",
  assignNameToIdentifySurvey: "Asigna un nombre para identificar este(a)  Encuesta",
  assignToFinalCustomer: "Asignar a {{dictionary.commonTerms.finalCustomer}}",
  assignedDate: "Fecha asignada",
  assignedDateOverview: "Fecha de asignación: ",
  assignedService: "Asignación de {{dictionary.commonTerms.service}}",
  assignedTo: "Asignado a",
  assignedUser: "Recurso",
  assignments: "asignaciones",
  attachmentWillBeRemoved: "El adjunto: \"{{filename}}\" será eliminado",
  attendChat: "Atender chat",
  audio: "Audio",
  autoCancelService: "Cancelar {{dictionary.commonTerms.service}} automáticamente",
  automaticNotification: "Notificación automática",
  availability: "Configurar Disponibilidad",
  availabilityDesccription: "Configura la disponibilidad de tus {{dictionary.profileNames.technicianPlural}} bajo múltiples parámetros, como {{dictionary.commonTerms.headquarter}} o tipo de {{dictionary.commonTerms.service}}.",
  availabilityTitle: "Disponibilidad",
  available: "Disponible",
  awaitingList: "Lista de espera",
  awaitingListDescription: "Crea un listado con  {{dictionary.commonTerms.finalCustomerPlural}} que están en espera para la asignación de {{dictionary.commonTerms.servicePlural}} o adelantar asignaciones actuales.",
  bannerSizeXY: "Banner (tamaño {{x}} por {{y}})",
  basename: "Nombre base",
  basenamePlaceholder: "ingrese nombre base",
  biologicalSex: "Sexo biológico",
  biologicalSexPlaceholder: "Seleccione un sexo biológico",
  biologicalSexRequired: "El sexo biológico es obligatorio",
  birthDate: "Fecha de nacimiento",
  birthdatePlaceholder: "Ingrese una fecha de nacimiento",
  birthdateRequired: "La fecha y hora de nacimiento es obligatoria",
  blackTheme: "Negro",
  blockDays: "Bloquear días específicos",
  blockDaysdescription: "Puedes agregar bloqueos en el agendamiento para días y horas espcecíficas",
  blockedDays: "Días bloqueados",
  bloodType: "Grupo sanguíneo",
  bloodTypePlaceholder: "Seleccione un grupo sanguíneo",
  bloodTypeRequired: "El grupo sanguíneo es obligatorio",
  blueTheme: "Azúl",
  boardFields: "Campos del Tablero",
  calendar: "Calendario",
  calendarFilters: "Filtrar calendario",
  cancel: "Cancelar",
  cancelAvailability: "Está intentando cancelar la disponibilidad de",
  cancelServiceTime: "Cancelar {{dictionary.commonTerms.service}} automáticamente en caso de sobrepasar el tiempo estimado sin ser Finalizado",
  cancelationDate: "Fecha de cancelación: ",
  canceledService: "Cancelación de {{dictionary.commonTerms.service}}",
  cancelingAvailabilityOf: "Está intentando cancelar la disponibilidad de",
  cancelingAvailabilityOfCalendar: "Está intentando cancelar {{dictionary.commonTerms.servicePlural}} de",
  cancellationDate: "Fecha",
  cancellationReasson: "Motivo de cancelación",
  cancellationReassons: "Motivos de cancelación",
  cancelledBy: "Cancelado por: ",
  cancelledService: "Permitir ofertar {{dictionary.commonTerms.servicePlural}} después de su cancelación",
  cantBeEmpty: "No puede estar vacío",
  cantHaveSpaces: "No puede tener espacios",
  cantSaveRecord: "No se puede guardar el registro",
  changesWillNotBeSaved: "Los cambios no serán guardados",
  characterText: "de {{n}} caracteres",
  chatCancelled: "Chat cancelado",
  chatClosed: "Chat cerrado",
  chatOpen: "Chat activo",
  chatUnassigned: "Chat sin asignar",
  chatWillBeAssignedToYou: "El chat será asignado a ti",
  chatWillBeFinished: "El chat será finalizado",
  cityPlaceholder: "Seleccione una ciudad de residencia",
  clear: "Limpiar",
  clickOrDragToUpload: "De click o arrastre hacia esta área para subir un archivo",
  closedChats: "Cerrados",
  collaborators: "Colaboradores",
  color: "Color",
  colorApplication: "Color de aplicación",
  colorMark: "Marcación de color",
  colorPlaceholder: "Ingrese color",
  colorRequired: "Es necesario seleccionar un color",
  compactView: "Vista compacta",
  company: "Compañia",
  companyAddress: "Servicios",
  completeMFService: "Completó el Formulario móvil",
  completeName: "Nombre completo",
  completeRFService: "Completó el Formulario de revisión",
  completedFormMobileBy: "Formulario móvil completado por: ",
  completedFormMobileDate: "Fecha completado en formulario móvil: ",
  completedFormReviewBy: "Formulario completado por: ",
  completedFormReviewDate: "Fecha completado formulario: ",
  config: "Configuración",
  configUserMessage: "Selecciona el tipo de usuario, ingresa la información básica y asigna una contraseña",
  configuration: "Configuración",
  confirm: "Confirmar",
  confirmPassword: "Confirmar contraseña",
  confirmation: "Confirmación",
  confirmedByFinalCustomer: "Confirmado por {{dictionary.commonTerms.finalCustomer}}",
  confirmedByFinalCustomerDate: "Fecha de confirmación {{dictionary.commonTerms.finalCustomer}}: ",
  confirmedByUser: "Confirmado",
  confirmedService: "Confirmó {{dictionary.commonTerms.service}}",
  confirmedServiceDescription: "La {{dictionary.commonTerms.service}} se marcara como confirmada",
  contact: "{{dictionary.commonTerms.contact}}",
  contactAddress: "Dirección de {{dictionary.commonTerms.contact}}",
  contactPhone: "Telefono de {{dictionary.commonTerms.contact}}",
  contactPlaceholder: "Seleccione {{dictionary.commonTerms.contact}}",
  contactReference: "Referencia de {{dictionary.commonTerms.contact}}",
  contactRequired: "{{dictionary.commonTerms.contact}} es obligatorio",
  contacts: "{{dictionary.commonTerms.contactPlural}}",
  continue: "Continuar",
  couldNotUploadFile: "No se pudo subir el archivo: {{filename}}",
  country: "País",
  countryPlaceholder: "Seleccione un país",
  countryRequired: "Debe seleccionar un país",
  create: "Crear",
  createAccount: "Crear Cuenta",
  createAppointment: "Crear {{dictionary.commonTerms.servicePlural}}",
  createAssetType: "Crear tipo de activo",
  createAvailability: "Nueva Disponibilidad",
  createCancellationReasson: "Crear motivo de cancelación",
  createContact: "Crear {{dictionary.commonTerms.contact}}",
  createCustomer: "Crear {{dictionary.commonTerms.customer}}",
  createDiary: "Creación de agenda",
  createDictionary: " Crear Diccionario",
  createFinalCustomer: "Crear {{dictionary.commonTerms.finalCustomer}}",
  createFinalCustomerTag: "Crear Tag para {{dictionary.commonTerms.finalCustomer}}",
  createHeadquarter: "Crear {{dictionary.commonTerms.headquarter}}",
  createHere: "Puedes crearlos desde acá.",
  createKey: "+ Crear nuevo key",
  createList: "Crear lista",
  createListNode: "Agregar nodo",
  createNode: "Crear Nodo",
  createNote: "Crear {{dictionary.commonTerms.note}}",
  createNotes: "Crear {{dictionary.commonTerms.notePlural}}",
  createNotesType: "Crear tipo de {{dictionary.commonTerms.notePlural}}",
  createNotification: "Crear notificación",
  createService: "Crear {{dictionary.commonTerms.service}}",
  createServicesFromMobile: "Crear {{dictionary.commonTerms.servicePlural}} para movil",
  createSkill: "Crear {{dictionary.commonTerms.skillsPlural}}",
  createUser: "Crear usuario",
  created: "¡Creado!",
  createdAt: "Fecha Creación",
  createdBy: "Creado por: ",
  createdRecords: "Registros creados",
  createdService: "Creación de {{dictionary.commonTerms.service}}",
  creationDate: "Fecha de creación",
  creationOfRequests: "Creación de solicitudes",
  creationOfRequestsDescription: "Si habilitas esta opción, el usuario interesado podrá crear una solicitud de un {{dictionary.commonTerms.service}} , este quedará con el estado Solicitado y requerirá que un administrador u {{dictionary.profileNames.operator}} asigne un {{dictionary.profileNames.technician}}  y defina el Tipo de {{dictionary.commonTerms.service}} ",
  creationType: "Tipo de creación",
  creationTypePlaceholder: "Seleccione Tipo de Agendamiento",
  creator: "Creador",
  currentValue: "Valor actual",
  custom: "Personalizado",
  customFieldPlaceholder: "Ingrese {{label}}",
  customFooterImage: "Usa una imagen personalizada para mostrar en el pie de página. El tamaño de la imagen debe ser de 782px x 80px (Ancho - Alto)",
  customHeaderImage: "Usa una imagen personalizada para mostrar en el encabezado de página. El tamaño de la imagen debe ser de 782px x 80px (Ancho - Alto)",
  customImageForReports: "Usa una imagen personalizada para mostrar en los reportes. El tamaño de la imagen debe ser de 360px x 135px (Ancho - Alto)",
  customList: "Lista Personalizada",
  customLists: "Listas Personalizadas",
  customPdfDescription: "Si habilitas esta opción los PDF que se generen a partir de la información de los tipos de Cita se verán acorde a la configuración del PDF personalizado",
  customer: "{{dictionary.commonTerms.customer}}",
  customerLocation: "Ubicación de {{dictionary.commonTerms.customer}}",
  customerName: "Nombre de {{dictionary.commonTerms.customer}}",
  customerPhone: "Telefono de {{dictionary.commonTerms.customer}}",
  customerPlaceholder: "Seleccione {{dictionary.commonTerms.customer}}",
  customerRequired: "{{dictionary.commonTerms.customer}} es obligatorio",
  customers: "{{dictionary.commonTerms.customerPlural}}",
  customersDescription: "Si tu entidad maneja listas de {{dictionary.commonTerms.customerPlural}}, podrás seleccionar el {{dictionary.commonTerms.customer}} específico al crear la {{dictionary.commonTerms.service}}",
  customizationAdminOptions: "Habilitar personalización (opciones de administración)",
  dashboard: "Dashboard",
  dataPolicyMessage: "política de datos ",
  dataTreatment: "Tratamiento de datos",
  dateAndHour: "Fecha/Hora",
  dateSend: "Fecha De Envío",
  day: "Día",
  days: "Días",
  decimalError: "Solo se aceptan valores enteros",
  delete: "Eliminar",
  departamentPlaceholder: "Seleccione el departamento de residencia",
  departmentPlaceholder: "Seleccione un departamento de residencia",
  description: "Descripción",
  descriptionErrorNotification: "Ya se tiene una notificación con esta misma configuración",
  descriptionOrComment: "Descripción o comentario",
  descriptionPlaceholder: "Ingrese una descripción",
  descriptionSelectServiceTypeClient: "Selecciona los tipos de {{dictionary.commonTerms.service}} para este {{dictionary.commonTerms.customer}}",
  descriptionSelfScheduling: "Habilita un enlace para permitir que tus {{dictionary.commonTerms.finalCustomerPlural}} realicen el agendamiento de {{dictionary.commonTerms.servicePlural}} por su cuenta",
  destination: "Destino",
  dictionary: "Diccionario",
  durationPlaceholder:'Selecciona la duracion',
  doctor: "Médico",
  documentType: "Tipo de documento",
  documentTypePlaceholder: "Selecione el tipo de documento",
  documentTypeRequired: "El tipo de documento es obligatorio",
  download: "Descargar",
  downloadBaseTemplate: "Descargar plantilla base para importar las listas",
  downloadImportsTemplateFile: "Descargar plantilla base para importaciones",
  downloadTemplate: "Descargar plantilla",
  doyouAgree: "¿Está de acuerdo?",
  duration: "Duración",
  durationService: "La duración de la {{dictionary.commonTerms.service}} es igual para todos los {{dictionary.profileNames.technicianPlural}}",
  earrings: "Pendientes",
  edit: "Editar",
  editAccount: "Editar cuenta",
  editAvailability: "Editar Disponibilidad",
  editCancellationReasson: "Editar motivo de cancelación",
  editContact: "Editar {{dictionary.commonTerms.contact}}",
  editCustomer: "Editar {{dictionary.commonTerms.customer}}",
  editDictionary: "Editar Diccionario",
  editFinalCustomer: "Editar {{dictionary.commonTerms.finalCustomer}}",
  editFinalCustomerTag: "Editar Tag para {{dictionary.commonTerms.finalCustomer}}",
  editHeadquarter: "Editar {{dictionary.commonTerms.headquarter}}",
  editIncidentType: "Editar tipo de {{dictionary.commonTerms.service}}",
  editInfoCustomers: "Puede modificar información de {{dictionary.commonTerms.customerPlural}}",
  editMainPage: "Editar página principal",
  editNotesType: "Editar tipo de notas",
  editNotification: "Editar notificación",
  editService: "Editar {{dictionary.commonTerms.service}}",
  editSkill: "Editar {{dictionary.commonTerms.skillsPlural}}",
  editState: "Editar Estado",
  editSurvey: "Editar Encuesta",
  editUser: "Editar usuario",
  editVariable: "Editar Variable",
  email: "Correo electrónico",
  emailExample: "hola@netuxtecnologia.com",
  emailMenu: "Email",
  emailPlaceholder: "Ingrese el correo electrónico",
  emailRequired: "El correo electrónico es obligatorio",
  emergency: "emergencias",
  emergencyContact: "Contacto de emergencia:",
  emergencyWork: "Atención en campo o",
  employee: "Empleado",
  employeePlural: "Empleados",
  enableAvailability: "Habilitar Disponibilidad",
  enableCustomPdf: "Habilitar PDF personalizado",
  enableForm: "Habilitar Formulario de revisión",
  enableSelfscheulding: "Habilitar esta disponibilidad para autoagendamiento web",
  enableServiceTypeForSelfScheduling: "Habilitar tipo de {{dictionary.commonTerms.service}} para autoagendamiento",
  enableServiceTypesClient: "¿Quieres habilitar todos los tipos de {{dictionary.commonTerms.service}} para este {{dictionary.commonTerms.customer}}?",
  enabledUsers: "Usuarios habilitados",
  endDate: "Fecha de fin",
  endHour: "Hora de fin",
  english: "Inglés",
  enterDateList: "Fecha de ingreso a lista",
  enterNote: "Ingresar {{dictionary.commonTerms.note}}",
  enterSession: "Ingresar",
  enterSubject: "Ingrese el asunto del correo electronico",
  enterYourData: "Registra tus datos",
  enterpriseName: "Nombre de la empresa",
  enterprisePlaceholder: "Netux S.A.S",
  entityName: "Nombre de entidad",
  eps: "Eps",
  error: "Error",
  errorCancelingService: "Ocurrió durante la cancelación de {{dictionary.commonTerms.service}}",
  errorCantSendMessageToClosedChat: "No se puede enviar mensajes a un chat cerrado",
  errorChatAlreadyAssignedToCurrentUser: "El chat ya se encuentra asignado a otro usuario",
  errorChatAssignedToAnotherUser: "El chat se encuentra asignado a otro usuario",
  errorChatNotActive: "El chat no se encuentra activo",
  errorChatNotFound: "Chat no encontrado",
  errorCreateMessage: "Ocurrió un error al crear mensaje",
  errorCreatingRecord: "Ocurrió un error creando el registro",
  errorDeleteSkill: "Se ha producido un error al intentar eliminar el registro",
  errorFinishingService: "Ocurrió durante la finalización de {{dictionary.commonTerms.service}}",
  errorRecordAlreadyExists: "El registro ya existe",
  errorRecordNotFound: "No se encontró el registro",
  errorRemovingRecord: "Ocurrió un error eliminando el registro",
  errorRetrievingColors: "Ocurrió un error cargando los colores",
  errorRetrievingRecord: "Ocurrió un error obteniendo el registro",
  errorRetrievingRecords: "Ocurrió un error obteniendo los registros",
  errorUpdatingRecord: "Ocurrió un error actualizando el registro",
  errorUploadingFile: "Ocurrió un error subiendo el archivo",
  estimatedTime: "Tiempo estimado para cancelar el Servicio automáticamente (minutos):",
  executionDate: "Fecha de ejecución",
  executionTime: "Hora de ejecución",
  exportToPdf: "Exportables en PDF",
  extendForm: "Con este formulario web puedes agregar campos que se deben diligenciar al momento de crear {{dictionary.commonTerms.servicePlural}}.",
  extendedWebForm: "Formulario Extendido Web",
  extendedWebFormParagraph: "Con este formulario web puede extender la información requerida para el autogendamiento",
  extension: "{{dictionary.commonTerms.extension}}",
  extensionPlural: "{{dictionary.commonTerms.extensionPlural}}",
  field: "Campo",
  fieldIsRequired: "Este campo es obligatorio",
  fields: "Campos",
  fileMustBeImage: "El archivo debe ser una imagen (jpeg o png)",
  fileSizeExceeded: "El tamaño del archivo excede el límite de {{size}} MB",
  fileTypeNotSupported: "Tipo de archivo no soportado",
  filesUploadingWillNotBeSaved: "Los archivos que se están subiendo no serán guardados",
  filesWithErrorWillNotBeSaved: "Los archivos con error no serán guardados",
  filterByServiceType: "Filtrar por tipo de {{dictionary.commonTerms.service}}",
  filterByTechnician: "Filtrar por {{dictionary.profileNames.technician}}",
  filterServices: "Filtrar {{dictionary.commonTerms.servicePlural}}",
  filters: "Filtros",
  finalCustomer: "{{dictionary.commonTerms.finalCustomer}}",
  finalCustomerAddress: "Dirección de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerEmail: "Correo de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerIdentification: "Identificación de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerInfo: "Información del {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerMobileNumber: "Celular de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerName: "Nombre de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerPhone: "Teléfono de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerPlaceholder: "Seleccione {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerRequired: "{{dictionary.commonTerms.finalCustomer}} es obligatorio",
  finalCustomerTags: "Tags de {{dictionary.commonTerms.finalCustomer}}",
  finalCustomers: "{{dictionary.commonTerms.finalCustomerPlural}}",
  finalCustomersDescription: "Si tu entidad emplea listado de {{dictionary.commonTerms.finalCustomerPlural}}, podrás seleccionar el {{dictionary.commonTerms.finalCustomer}} específico al crear {{dictionary.commonTerms.service}}.",
  finalDate: "Fecha final",
  finalEdition: "¿Permitir edición después de finalizar {{dictionary.commonTerms.service}}?",
  finish: "Finalizar",
  finishByFinalCustomerService: "Finalización por {{dictionary.commonTerms.finalCustomer}}",
  finishChat: "Finalizar chat",
  finishDate: "Fecha de finalización",
  finishService: "Finalizó {{dictionary.commonTerms.service}}",
  finishTime: "Hora de finalización",
  finishTimeRequired: "Hora de finalización requerida",
  finished: "Finalizado",
  finishedBy: "Finalizado por:",
  finishedRetries: "Reintentos finalizados",
  formPlaceholder: "Seleccione un formulario",
  friday: "Viernes",
  from: "Desde",
  fromNAvailable: "de {{n}}",
  fuchsiaTheme: "Magenta",
  fullView: "Vista completa",
  futureReminder: "Recordatorio futuro",
  generalInfo: "Información General",
  generalOptions: "Opciones Generales",
  generateLinkText: "Generar enlaces específicos por {{dictionary.profileNames.technician}}",
  generateReport: "Generar reporte",
  goBack: "Regresar",
  grayTheme: "Gris",
  greenTheme: "Verde",
  group: "Grupo",
  groupName: "Nombre de Grupo",
  headquarter: "{{dictionary.commonTerms.headquarter}}",
  headquarterAddress: "Dirección de {{dictionary.commonTerms.headquarter}}",
  headquarterDefault: "{{dictionary.commonTerms.headquarter}} general",
  headquarterDescription: "Seleccionar {{dictionary.commonTerms.headquarter}} a la que pertenece este {{dictionary.profileNames.operator}}",
  headquarterDescriptionTechnician: "Seleccionar {{dictionary.commonTerms.headquarter}} a la que pertenece este {{dictionary.profileNames.technician}}",
  headquarterName: "Nombre de {{dictionary.commonTerms.headquarter}}",
  headquarterPlaceholder: "Selecione {{dictionary.commonTerms.headquarter}}",
  headquarters: "{{dictionary.commonTerms.headquarterPlural}}",
  healthInsurance: "EPS",
  healthInsuranceCode: "Código de la EPS",
  healthInsurancePlaceholder: "Seleccione una EPS",
  healthInsuranceRequired: "La EPS es obligatoria",
  here: "AQUÍ",
  highPriority: "{{dictionary.commonTerms.highPriority}}",
  hourRequired: "La hora es requerida",
  hours: "Horas",
  hoursavailability: "Horarios y disponibilidad",
  hoursdescription: "Si habilitas horarios para el usuario, no podrás asignar {{dictionary.commonTerms.servicePlural}} en horarios que estén por fuera de sus horarios de disponibilidad",
  howOften: "¿Cada cuánto? ",
  howOftenPlaceholder: "Selecciona cada cuánto",
  htmlEditorDescription: "Editor en HTML",
  id: "Id",
  idWebFormRequired: "El Formulario es obligatorio",
  identification: "Numero de identificación",
  identificationAlreadyExists: "La identificación ya existe",
  identificationPlaceholder: "Ingrese la identificación",
  identificationRequired: "La identificación es obligatoria",
  identificationandScearchRequired: "Debes realizar la búsqueda",
  identifier: "Identificador",
  idiom: "Idioma",
  image: "Imagen",
  imageDimensionsError: "El tamaño de la imagen debe ser {{width}}px por {{height}}px",
  import: "Importar",
  importCustomList: "Importar listas personalizadas",
  importCustomListText: "Descarga la plantilla",
  importCustomListTextModal: "para diligenciar la información y luego realiza la importación dando clic en el siguiente botón.",
  importFinalCustomers: "Importar {{dictionary.commonTerms.finalCustomerPlural}}",
  importFinalCustomersResult: "Resultado de importación de {{dictionary.commonTerms.finalCustomerPlural}}",
  importListItems: "Importar ítems de lista",
  incidentTypeCreate: "Crear Tipo de {{dictionary.commonTerms.service}}",
  incidentTypeEdit: "Editar Tipo de {{dictionary.commonTerms.service}}",
  incidentTypeText: "Crear tipo de {{dictionary.commonTerms.service}} o editar tipo de {{dictionary.commonTerms.service}} según el caso.",
  includeInOpportunitiesReport: "Incluir en reporte de oportunidad de {{dictionary.commonTerms.servicePlural}}",
  info: "Información",
  infoMessageWhatsapp: "Esta información no formará parte del mensaje, servirá para luego identificar en el historial qué tipo de mensaje enviaste",
  infoPatien: "Información del {{dictionary.commonTerms.finalCustomer}}",
  infoRequired: "Esta información es obligatoria",
  infoServiceStatus: "Puede modificar el estado de {{dictionary.commonTerms.service}}",
  infoServiceStatusFromMobile: "Permitir modificar el estado de {{dictionary.commonTerms.service}} en web",
  infoSwitchAvailability: "Puede modificar la disponibilidad avanzada",
  initialDate: "Fecha inicial",
  inspectFormService: "Con este formulario puede realizar una revisión final de su {{dictionary.commonTerms.service}}.",
  integrationWhatsapp: "Integración Whatsapp",
  interested: "{{dictionary.profileNames.interested}}",
  isNotAValidEmail: "No es un correo electrónico valido",
  itemNumberN: "ítem #{{n}}",
  itemWillBeRemoved: "Este elemento será eliminado",
  keyInvalid: "El identifcador es invalido",
  keyLabel: "Identificador",
  keyRequired: "El identificador es obligatorio",
  landlinePhone: "Teléfono fijo",
  landlinePhoneNumberPlaceholder: "Ingrese el teléfono",
  landlinePhonePlaceholder: "Ingrese un teléfono fijo",
  landlinePhoneRequired: "El teléfono fijo es obligatorio",
  language: "Lenguaje",
  languageRequired: "El lengauaje es obligatorio",
  languageText: "Seleccione un lenguaje",
  lastLocation: "Ubicación final",
  lastname1: "Primer apellido",
  lastname1Placeholder: "Ingrese el primer apellido",
  lastname1Required: "El primer apellido es obligatorio",
  lastname2: "Segundo apellido",
  lastname2Placeholder: "Ingrese el segundo apellido",
  lastname2Required: "El segundo apellido es obligatorio",
  legal: "Legales",
  legalName: "Nombre legal",
  legalNamePlaceholder: "Ingrese el nombre legal",
  legalSize: "Tamaño legal",
  length: "Duración",
  lengthMaxValidation: "{{label}} debe contener máximo {{max}} caracteres",
  lengthMinValidation: "{{label}} debe contener mínimo {{min}} caracteres",
  lessThanOrEqualValidation: "{{label}} debe ser menor o igual a {{max}}",
  letterSize: "Tamaño carta",
  lettersAndNumbersOnly: "Solo se permiten letras y números",
  limitCharacters: "Excediste el límite de caracteres",
  limitSelfScheduling: "Limitar el período de autoagendamiento",
  limitSelfSchedulingDescription: "Limitar el período futuro habilitado en la página de autoagendamiento al seleccionar {{dictionary.commonTerms.servicePlural}}",
  link: "Enlace",
  linkCreateHeadquarter: " puedes crear más {{dictionary.commonTerms.headquarterPlural}}",
  linkCreateSkills: " puedes crear más {{dictionary.commonTerms.skillsPlural}}.",
  links: "Enlaces",
  list: "Lista",
  loading: "Cargando...",
  loadingItems: "Cargando {{itemsName}}...",
  loginAction: "Ingresar",
  loginTitle: "Inicio de sesión",
  logo: "logo",
  logoSizeXY: "Logo (tamaño {{x}} por {{y}})",
  logoText: "Sube tu logo en formato .PNG para que aparezca  en la esquina superior izquierda al interior de la plataforma web. Tamaño obligatorio (Ancho - Alto) 400 x 80.",
  lowPriority: "{{dictionary.commonTerms.lowPriority}}",
  mainContact: "Nombre de {{dictionary.commonTerms.contact}} principal",
  mainContactPlaceholder: "Ingrese el nombre de {{dictionary.commonTerms.contact}} principal",
  mainContactRequired: "Nombre de {{dictionary.commonTerms.contact}} principal es obligatorio",
  mainPageInstructions: "Instrucciones página principal",
  mainTitle: "Título principal",
  map: "Mapa",
  maxDifferenceIs1Month: "La diferencia máxima entre las fechas debe ser de 1 mes",
  medic: "Médico",
  medicPlural: "Médicos",
  mediumPriority: "{{dictionary.commonTerms.mediumPriority}}",
  mentionedAssignments: "Todas las asignaciones mencionadas quedarán ",
  message: "Mensaje",
  messageAttempts: "Se intentará enviar un mensaje a todos los {{dictionary.commonTerms.finalCustomerPlural}}  ({{n}})",
  messageDataTerms: "de datos y los ",
  messageDeleteEarring: "Se omitirá la reprogramación y se eliminará del listado de pendientes",
  messageDeletePendingIncident: "Se omitirá la reprogramación y se eliminará del listado de pendientes",
  messageRequired: "El mensaje es obligatorio",
  messageTermsAndConditions: "He leído y acepto la ",
  messengerService: "Mensajería",
  messengerServiceDescription: "Envía mensajes y correos  personalizados a grupos o segmentos de Pacientes directamente, o luego del cumplimiento de algunas reglas",
  messengerServiceWhatsapp: "Mensajería: Whatsapp",
  minDateError: "la fecha mínima es {{minDate}}",
  minRotationTime: "El tiempo de rotación debe ser mínimo de {{n}} minutos",
  minTimeError: "la hora mínima es {{minTime}}",
  minutes: "Minutos",
  mobileAppColor: "Colores de la app móvil",
  mobileAppDescription: "si tu entidad utiliza la aplicación móvil, podrás configurar algunas opciones de los usuarios",
  mobileAppModuleDescription: "Si tu entidad utiliza la aplicación móvil ,podrás configurar algunas opciones de los usuarios",
  mobileAppModuleText: "Configuraciones para App móvil",
  mobileForm: "Formulario móvil",
  mobileFormLink: "Puedes crear nuevos formularios o editarlos con los campos necesarios.",
  mobileFormRequired: "Debe seleccionar un formulario móvil",
  mobileLogo: "Logo Móvil",
  mobileLogoText: "Sube tu logo en formato .PNG para que aparezca en la barra de menú de la aplicación móvil. Tamaño obligatorio (Ancho - Alto) 270 x 100 pixeles.",
  mobilePhone: "Celular",
  mobilePhonePlaceholder: "Ingrese un número de teléfono celular",
  mobilePhoneRequired: "Ingrese el celular",
  modules: "Módulos",
  monday: "Lunes",
  monthlySurveys: "Encuestas Mes",
  months: "Meses",
  moveIncident: "Mover {{dictionary.commonTerms.service}}",
  moveIncidentDescription: "Se realizaran los siguientes cambios",
  movedToPending: "Se moverán al listado de pendientes para no olvidar su reprogramación.",
  nAppointments: "{{n}} {{dictionary.commonTerms.servicePlural}}",
  nAttachmentFiles: "{{n}} archivos adjuntos",
  nHours: "{{n}} horas",
  nMinutes: "{{n}} minutos",
  name: "Nombre",
  name1: "Primer nombre",
  name1Placeholder: "Ingrese el primer nombre",
  name1Required: "El primer nombre es obligatorio",
  name2: "Segundo nombre",
  name2Placeholder: "Ingrese el segundo nombre",
  name2Required: "El segundo nombre es obligatorio",
  nameAccount: "Nombre de la cuenta",
  namePlaceholder: "Ingrese el nombre",
  nameRequired: "El nombre es obligatorio",
  nameTheMessage: "Asigna un nombre al mensaje",
  nationalityCountry: "País de nacionalidad",
  nationalityCountryPlaceholder: "Seleccione un país de nacionalidad",
  nationalityCountryRequired: "El país de nacionalidad es obligatorio",
  newAccounts: "Crear",
  newAppointment: "Nueva {{dictionary.commonTerms.service}}",
  newNotification: "Nueva notificación",
  newValue: "Nuevo valor",
  next: "Siguiente",
  nit: "Nit",
  no: "No",
  noApiKeysGenerated: "No se han generado API keys",
  noApiKeysGeneratedWhatsapp: "No se ha generado un key para Whatsapp",
  noChatSelected: "No se ha seleccionado un chat",
  noChatsAvailable: "No hay chats disponibles",
  noComplete: "Asignar estado \"No completado\" en caso de ser activada una alerta",
  noDataTreatmentPolicy: "No hay política de tratamiento de datos",
  noLegalTerms: "No hay términos legales",
  noScheduleTimesAvailable: "El {{dictionary.profileNames.technician}} seleccionado no tiene horarios disponibles, por favor seleccione otro.",
  noSchedulesAdded: "No se han agregado horarios",
  noValue: "Sin valor asignado",
  notAvailable: "No disponible",
  notDelivered: "No Entregado",
  notImportedRecords: "Registros que no fueron importados",
  notPrioritary: "{{dictionary.commonTerms.notPrioritary}}",
  note: "{{dictionary.commonTerms.note}}",
  notePlural: "{{dictionary.commonTerms.notePlural}}",
  noteTypes: "Tipo de {{dictionary.commonTerms.note}}",
  notes: "{{dictionary.commonTerms.notePlural}}",
  notesTypes: "Tipos de {{dictionary.commonTerms.notePlural}}",
  notification: "Notificación",
  notificationEvent: "Evento",
  notificationEventRequired: "El tipo de evento es obligatorio",
  notificationHour: "Notificar a una hora especifica",
  notificationSent: "Notificación enviada",
  notificationType: "Tipo de notificación",
  notificationTypeRequired: "El tipo de notificación es obligatorio",
  notifications: "Notificaciones",
  notifyTo: "Notificar a",
  notifyToRequired: "El destinatario es obligatorio",
  number: "Número",
  numberAttemps: "Numero de intentos",
  obligatory: "Obligatoriedad",
  of: "el",
  ok: "Ok",
  onlyNumbersValidation: "{{label}} solo permite caracteres numéricos",
  operator: "{{dictionary.profileNames.operator}}",
  optional: "(Opcional)",
  optionalMobilePhone: "Celular opcional",
  optionalMobilePhonePlaceholder: "Ingrese un número de teléfono celular opcional",
  options: "Opciones",
  orangeTheme: "Naranja",
  origin: "Origen",
  other: "Otro",
  otherOptions: "Otras opciones",
  others: "Otros",
  pageFooterImage: "Imagen de pie de Página",
  pageHeaderImage: "Imagen de Encabezado de página",
  paperType: "Tamaño de papel",
  passwordAndConfirmationsMustBeEquals: "La confirmación de contraseña no coincide",
  passwordDigits: "La contraseña debe contener {{n}} dígitos",
  passwordLabel: "Contraseña",
  passwordLength: "La contraseña debe contener {{n}} caracteres",
  passwordLowercaseLetters: "La contraseña debe contener {{n}} letras minúsculas",
  passwordPlaceholder: "Escribe tu contraseña",
  passwordRequiredMessage: "La contraseña es obligatoria",
  passwordSpecialChars: "La contraseña debe contener {{n}} de estos caracteres: {{acceptedChars}}",
  passwordUppercaseLetters: "La contraseña debe contener {{n}} letras mayúsculas",
  patiensHistoryAppointments: "{{dictionary.commonTerms.finalCustomerPlural}} que han pedido los siguientes tipos de {{dictionary.commonTerms.servicePlural}}",
  patientHasOverlaps: "{{dictionary.commonTerms.finalCustomer}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario",
  patientIdConfirmed: "Identificación del Paciente Confirmado",
  patientIdRejected: "Identificación del Paciente Rechazado",
  pdfConfigDescription: "Personaliza parcial o totalmente los codumentos PDF que se generan",
  pdfConfigText: "Configuración de PDF",
  pdfFormat: "Formato PDF",
  pdfFormatBottomBorder: "Borde inferior (cm)",
  pdfFormatCopyHTMLContent: "Copiar contenido HTML",
  pdfFormatLeftBorder: "Borde izquierdo (cm)",
  pdfFormatPlaceholder: "Selecciona formato ",
  pdfFormatRightBorder: "Borde derecho (cm)",
  pdfFormatTopBorder: "Borde superior (cm)",
  pdfFormatsCustom: "Personalizados",
  pdfFormatsStandard: "Estándar",
  pdfSettings: "Configuración de PDF",
  pendingAssignments: "Pendientes por asignar ",
  pendings: "Pendientes",
  pendingsBoard: "Tablero de pendientes",
  pendingsBoardDescription: "Visualiza un tablero con la lista de {{dictionary.commonTerms.servicePlural}} pendientes",
  permission: "Permissos",
  permissionDenied: "No tiene permiso para esta operación",
  personInCharge: "Responsable",
  personalName: "Nombre del personal",
  personalQuestion: "3.¿Cómo denominas a tu personal?",
  personalization: "Personalización",
  petitionBy: "Petición hecha por: ",
  petitionDate: "Fecha de petición: ",
  phoneNumber: "Teléfono",
  phoneNumberRequired: "El teléfono es obligatorio",
  plural: "Plural",
  pluralPlaceholder: "Ingrese Plural",
  powerBy: "Powered By",
  prefix: "Prefijo",
  prefixNotAvailable: "Prefijo no disponible",
  prefixPlaceholder: "netux",
  prefixRequired: "El prefijo es obligatorio",
  prefixText: "Escribe un prefijo o identificador",
  preview: "Preview",
  previous: "Atrás",
  priority: "{{dictionary.commonTerms.priority}}",
  priorityPlaceholder: "selecciona la prioridad",
  priorityRequired: "Prioridad requerida",
  programmed: "Programado",
  programming: "Programación",
  programmingDescription: "Programa {{dictionary.commonTerms.service}} con antelación para que se repitan múltiples veces en el futuro.",
  publicName: "Nombre público",
  publicNameRequired: "El nombre público es obligatorio",
  purpleTheme: "Morado",
  reCaptchaError: "Error en la verificación, por favor intente de nuevo",
  recordWillBeRemoved: "El registro: {{recordDescription}} será eliminado completamente. Esta acción no se puede deshacer",
  redTheme: "Rojo",
  reducedView: "Vista reducida",
  reference: "Referencia",
  referencePlaceholder: "Ingrese la referencia",
  referenceRequired: "La referencia es obligatoria",
  regime: "Régimen",
  regimePlaceholder: "Seleccione un régimen",
  regimeRequired: "El régimen es obligatorio",
  rejected: "Rechazado",
  rejectedBy: "Rechazado Por: ",
  rejectedDate: "Fecha de rechazo: ",
  remainingAssignments: "asignaciones restantes quedarán",
  remindWhen: "Recordar cuando",
  remindWhenRequired: "El cuando recordar es obligatorio",
  reminder: "Recordatorio",
  remove: "Eliminar",
  removeDate: "se eliminará la fecha {{-from}} - {{-to}}",
  removeUserExist: "No es posible eliminar el {{dictionary.commonTerms.finalCustomer}}",
  removeUserExistDescription: "Debido a que tiene citas agendadas",
  removed: "¡Eliminado!",
  repeatSurvey: "Repetir Encuesta",
  repeatTimes: "¿Cuántas veces se repetirá?",
  repeatTimesPlaceholder: "Selecciona cantidad de veces",
  report: "Reporte",
  reportFieldsConfig: "Configurar los campos del reporte",
  reportLogo: "Logo del reporte",
  reports: "Reportes",
  reprogramCount: "Se reprogramarán las",
  reprogrammedCount: "Se reprogramarán ",
  reschedule: "Reprogramar",
  rescheduleCount: "Se reprogramarán las",
  rescheduleCountTranslation: "Serán reprogramados",
  reschedulelater: "Reprogramar luego",
  reserveAnotherAppointment: "Agendar otra {{dictionary.commonTerms.service}}",
  reserveAppointment: "Reservar {{dictionary.commonTerms.service}}",
  residenceCity: "Ciudad de residencia",
  residenceCityRequired: "La ciudad de residencia es obligatoria",
  residenceDepartment: "Departamento de residencia",
  residenceDepartmentPlaceholder: "Seleccione un departamento de residencia",
  residenceDepartmentRequired: "La departamento de residencia es obligatoria",
  resource: "Recurso",
  resourceDescription: "Seleccionar {{dictionary.profileNames.technicianPlural}} que puede gestionar este {{dictionary.profileNames.operator}}",
  resourceManagement: "Gestión de {{dictionary.profileNames.technicianPlural}}",
  resourceName: "Nombre de {{dictionary.profileNames.technician}}",
  resourcePlaceholder: "Selecciona {{dictionary.profileNames.technician}}",
  resources: "Recursos",
  responseDate: "Fecha De Respuesta",
  responses: "Respuestas",
  retried: "Reintentado",
  retryNumber: "Numero de reintentos",
  retryPeriod: "Periodo de reintentos",
  retrySendingAfter24Hours: "Se debe reintentar el envío luego de 24 horas sin respuesta",
  return: "Volver",
  review: "{{dictionary.commonTerms.review}}",
  reviewPlural: "{{dictionary.commonTerms.reviewPlural}}",
  reviewYourAvailability: "Revisa tu disponibilidad, se enviará la confirmación a tu correo electrónico",
  rotationTime: "Tiempo de rotación",
  rotationTimeMinutes: "Tiempo de rotación (minutos)",
  rotationTimeRequired: "El tiempo de rotación es obligatorio",
  rowInFile: "Fila en el archivo",
  saturday: "Sábado",
  save: "Guardar",
  scheduleLock: "Bloqueo de horario",
  scheduleTimeNotAvailable: "Horario no disponible",
  scheduled: "Programado",
  scheduledAppointments: "Las siguientes {{dictionary.commonTerms.servicePlural}} están agendadas en ese horario:",
  search: "Buscar",
  searchAppointment: "Buscar {{dictionary.commonTerms.service}}",
  searchAssignment: "Buscar asignación",
  searchChat: "Buscar chat",
  searchCustomerPlaceholder: "Ingrese nombre o identificación",
  searchRequired: "Debes realizar la búsqueda",
  searchSkillsPlaceholder: "Ingrese habilidad",
  searchSpecialist: "Buscar Especialista",
  secondaryEmail: "Correo electrónico secundario",
  secondaryEmailPlaceholder: "Ingrese el correo electrónico secundario",
  seeAllServices: "Permite visualizar todos los tipos de {{dictionary.commonTerms.servicePlural}}",
  seeAllTask: "Permite ver Tareas asignadas a cualquier usuario Operador",
  seeAllWorkflows: " Permite ver Flujos de trabajo asignados a cualquier usuario Operador",
  selectAppointmentDate: "Seleccione un día para su cita",
  selectAppointmentTime: "Seleccione una hora",
  selectAppointmentsTimes: "Seleccione las horas para la cita",
  selectAsset: "Seleccione {{dictionary.commonTerms.asset}}",
  selectAtLeastOneDay: "Debe seleccionar al menos un día",
  selectCancellationReasson: "Seleccione el motivo de cancelación para la cita",
  selectColorForExportableFormat: "Seleccione un color para ser usado en el formato exportable",
  selectEpsPlaceHolder: "Seleccione eps",
  selectFieldsToDisplay: "Seleccione los campos que desea visualizar en los reportes",
  selectFile: "Seleccionar archivo",
  selectForm: "Selecciona el formulario que se diligenciará desde la aplicación móvil.",
  selectFormat: "Selecciona un formato",
  selectGroup: "Seleccione un grupo",
  selectHeadquarter: "Selecciona las {{dictionary.commonTerms.headquarterPlural}} que aplican para este tipo de {{dictionary.commonTerms.service}}. También",
  selectMessageSendingStart: "Selecciona el momento en que se empezará el envío de los mensajes",
  selectPatienText: "Selecciona los campos específicos del {{dictionary.commonTerms.finalCustomer}} que éste deberá diligenciar al momento de autoagendarse",
  selectPreferredAction: "Seleccione la acción que prefiera aplicar",
  selectRecipientForMessages: "Selecciona a quién enviarás los mensajes",
  selectSkills: "Selecciona las {{dictionary.commonTerms.skillsPlural}} que aplican para este tipo de {{dictionary.commonTerms.service}}. También",
  selectStatus: "Seleccione un estado",
  selectTechnicianAndDate: "Seleccione un {{dictionary.profileNames.technician}} y una fecha para buscar la posibilidad de reasignación:",
  selectTheAppointment: "Elige la cita",
  selectTypeUserPlaceholder: "Selecciona el tipo de usuario",
  userPlaceholder: "Selecciona el usuario",
  selectWebForm: "Seleccione formulario",
  selectWhoYou: "Selecciona a quién le enviarás tu mensaje",
  selfScheduled: "Autoagendado",
  selfScheduling: "Autoagendamiento",
  selfSchedulingAccessLink: "Enlace de acceso",
  selfSchedulingInstructions: "Instrucciones para este tipo de {{dictionary.commonTerms.service}}",
  selfSchedulingLengthInstructions: "Defina cuanto debe durar, tenga en cuenta que este tiempo determinará las horas a mostrar en la página de autoagendamiento",
  selfSchedulingPublicNameInstructions: "Este es el nombre que veran los usuarios en la página de autoagendamiento",
  send: "Enviar",
  sent: "Enviado",
  sentMessages: "Enviar mensajes",
  service: "{{dictionary.commonTerms.service}}",
  serviceAssigned: "Asignación de {{dictionary.commonTerms.service}}",
  serviceAssignment: "Asignación de {{dictionary.commonTerms.servicePlural}}",
  serviceCanceled: "Cancelación de {{dictionary.commonTerms.service}}",
  serviceConfirmation: "Confirmación de {{dictionary.commonTerms.service}}",
  serviceConfrimation: "confirmación de {{dictionary.commonTerms.service}}",
  serviceDate: "Fecha",
  serviceDatePlaceholder: "Ingrese la fecha",
  serviceDateRequired: "La fecha es obligatoria",
  serviceDescription: "Descripción",
  serviceDescriptionPlaceholder: "Ingrese descripción",
  serviceDescriptionRequired: "Descripción es obligatoria",
  serviceModified: "Modificación de {{dictionary.commonTerms.service}}",
  serviceName: "Nombre de {{dictionary.commonTerms.service}}",
  serviceNamePlaceholder: "Ingrese nombre {{dictionary.commonTerms.service}}",
  serviceNameRequired: "Nombre {{dictionary.commonTerms.service}} es obligatorio",
  serviceTime: "Hora",
  serviceTimePlaceholder: "Ingrese la hora",
  serviceTimeRequired: "La hora es obligatoria",
  serviceType: "Tipo de {{dictionary.commonTerms.service}}",
  serviceTypeNotAssignedToHeadquarter: "El tipo de {{dictionary.commonTerms.service}} no está asignado a {{dictionary.commonTerms.headquarter}}",
  serviceTypeNotAssignedToX: "El tipo de {{dictionary.commonTerms.service}} no está asignado a {{x}}",
  serviceTypePlaceholder: "Seleccione un tipo de {{dictionary.commonTerms.service}}",
  serviceTypeRequired: "Tipo de {{dictionary.commonTerms.service}} es obligatorio",
  serviceTypeText: "Nuevo tipo de {{dictionary.commonTerms.service}}",
  serviceTypes: "Tipo de {{dictionary.commonTerms.servicePlural}}",
  serviceWillBeCanceled: "{{dictionary.commonTerms.service}} se cancelará",
  serviceWillBeFinished: "{{dictionary.commonTerms.service}} se finalizará",
  services: "{{dictionary.commonTerms.servicePlural}}",
  servicesByType: "{{dictionary.commonTerms.servicePlural}} por tipo",
  servicesCanceled: "{{dictionary.commonTerms.servicePlural}} que se cancelaron",
  servicesConfirmed: "{{dictionary.commonTerms.servicePlural}} que se confirmaron",
  servicesCreated: "{{dictionary.commonTerms.servicePlural}} que se crearon",
  servicesHistory: "Historial de {{dictionary.commonTerms.servicePlural}}",
  servicesLanding: "Landing de {{dictionary.commonTerms.servicePlural}}",
  servicesPerDay: "{{dictionary.commonTerms.servicePlural}} por día",
  servicesSelfScheduled: "{{dictionary.commonTerms.servicePlural}} que se autoagendaron",
  servicesTypesAssigned: "Seleccione los tipos de {{dictionary.commonTerms.servicePlural}} con permiso de visualización",
  shippingDate: "Fecha de envio",
  shortDescription: "Descripción corta",
  signOut: "Cerrar sesión",
  singular: "Singular",
  singularPlaceholder: "Ingrese singular",
  skillPlaceholder: "Selecciona las habilidades",
  skillWillBeRemoved: "Este registro será eliminado completamente.",
  skills: "{{dictionary.commonTerms.skillsPlural}}",
  skillsDescription: " Selecciona {{dictionary.commonTerms.skillsPlural}} para este Recurso. ",
  skillsDescriptionFinish: "y asociarlas a los tipos de {{dictionary.commonTerms.service}}",
  skillsDescriptionLink: "También puedes crear más {{dictionary.commonTerms.skillsPlural}} ",
  skillsRequired: "Debe seleccionar al menos una habilidad",
  spanish: "Español",
  specialist: "Especialista",
  specialistPlural: "Especialistas",
  specificEmail: "Correo específico",
  startDate: "Fecha de inicio",
  startHour: "Hora de inicio",
  startTime: "Hora de inicio",
  startTimeRequired: "Hora de inicio requerida",
  startTimeShouldBeGreater: "La hora de finalización debe ser mayor",
  states: "Estados",
  status: "Estado",
  statusChangeNotAllowed: "Cambio de estado no permitido",
  statusRequired: "Estado requerido",
  subject: "Asunto",
  subjectRequired: "El asunto es obligatorio",
  succesSendMessages: "Se intentará enviar un mensaje a todos los {{dictionary.commonTerms.finalCustomerPlural}}. Tenga en cuenta que si alguno de ellos no tiene un número válido registrado, no recibirá la notificación.",
  success: "Éxito",
  successCancelingService: "Cancelación de {{dictionary.commonTerms.service}} exitosa",
  successCreateToken: "Token generado con éxito",
  successCreatingCancellation: "Cancelación creada con éxito",
  successCreatingRecord: "Registro creado con éxito",
  successDeleteSkill: "Registro eliminado con éxito",
  successFinishingService: "Finalización de {{dictionary.commonTerms.service}} exitosa",
  successRemoveService: "{{dictionary.commonTerms.service}} eliminada con éxito",
  successRemovingRecord: "Registro eliminado con exito",
  successUpdatingRecord: "Registro actualizado con exito",
  successUploadingFile: "Archivo subido con éxito",
  sunday: "Domingo",
  supportChat: "Chat de soporte",
  supportChatDescription: "Habilita el chat de soporte para que los {{dictionary.commonTerms.finalCustomerPlural}} puedan comunicarse con el equipo de soporte",
  surveyHistory: "Histórico de Encuestas",
  surveyInfoNotPartOfMessage: "Esta información no será parte del mensaje, servirá para identificar Encuestas luego en el histórico",
  surveys: "Encuestas",
  surveysDescription: "Realiza el envío de Encuestas por medio de Whatsapp a tus Clientes finales",
  surveysGroup: "Grupo de Encuestas",
  synchronizedBy: "Sincronizados por: ",
  synchronizedDate: "Fecha de sincronización: ",
  tagsPlaceholder: "Ingresa los Tags del {{dictionary.commonTerms.finalCustomer}}",
  task: "Tareas",
  technician: "{{dictionary.profileNames.technician}}",
  technicianPlaceholder: "Seleccione {{dictionary.profileNames.technician}}",
  technicianPlural: "{{dictionary.profileNames.technicianPlural}}",
  technicianRequired: "{{dictionary.profileNames.technician}} es obligatorio",
  technicianScheduleOverlaps: "{{dictionary.profileNames.technician}} ya tiene otra asignación que se cruza con la hora que desea asignar",
  techniciansMap: "Mapa de Recursos",
  techniciansMapDescription: "Visualiza en un mapa las últimas ubicaciones por los usuarios móviles",
  templateRequired: "La plantilla es obligatoria",
  templates: "Plantillas",
  terms: "Términos",
  termsAndConditionsMessage: "términos y condiciones",
  termsAndConditionsTitle: "Términos Y Condiciones",
  textAccountuser: "Para ingresar a tu cuenta puedes usar tu nombre de usuario {{prefix}}.{{username}} y la contraseña que ingresaste",
  textCopyToClipBoard: "El texto se ha copiado al portapapeles",
  textGenerate: "Se ha generado una nueva api key",
  the: " de",
  theFollowingAppointments: "Las siguientes asignaciones están agendadas en ese horario:",
  theFollowingAppointmentsCalendar: "En este horario se encontraron las siguientes asignaciones:",
  thisFieldIsRequired: "Este campo es obligatorio",
  thisRecordWillBeRemoved: "Este registro será eliminado",
  thursday: "Jueves",
  time: "Tiempo",
  timeEstimated: "Tiempo estimado para cancelar {{dictionary.commonTerms.service}}",
  timeExecution: "Tiempo de ejecución en minutos",
  timeFormat: "Formato del horario",
  timeUnit: "Unidad de tiempo",
  timeUnitPlaceholder: "Días, semanas, meses, años",
  timeUnitPlaceholderPleace: "Selecciona unidad de tiempo",
  timeUnitRequired: "Unidad de tiempo",
  timeZone: "Zona horaria",
  timeZoneExample: "(UTC-05:00) Bogota, Lima, Quito",
  timeZoneText: "Seleccione su zona horaria",
  timezoneRequired: "La zona horaria es obligatoria",
  titleCardselfScheduling: "Página de autoagendamiento",
  titleErrorNotification: "No es posible crear esta notificación",
  tittleIntegrationwhatsapp: "Integración Whatsapp",
  to: "a",
  tokenMetaMessage: "Ingresa el token permanente generado por Meta",
  topic: "{{dictionary.commonTerms.topic}}",
  topicPlural: "{{dictionary.commonTerms.topicPlural}}",
  totalitems: "Total de items",
  trialPeriod: "Periodo de intentos",
  tuesday: "Martes",
  typeOfExam: "Tipo de examen",
  typeUsertext: "Selecciona el tipo de usuario, ingresa la Información básica y asigna una contraseña",
  unassignedChats: "Sin asignar",
  until: "Hasta",
  updateList: "Editar lista",
  updateListNode: "Actualizar nodo",
  updatePasswordText: "Cambiar contraseña",
  updated: "¡Actualizado!",
  updatedBy: "Actualizado por: ",
  updatedDate: "Fecha de actualización: ",
  updatedMobileFormDate: "Fecha de formulario móvil completado: ",
  updatedMobileFormdBy: "Formulario móvil actualizado por: ",
  updatedRecords: "Registros actualizados",
  uploadImportsTemplateMessage: "Subir plantilla diligenciada",
  uploadTemplate: "Subir plantilla",
  user: "Usuario",
  userAdmin: "Usuario administrador",
  userAdminPlaceholder: "admin",
  userAdminRequired: "Usuario administrador es obligatorio",
  userCannotPerformService: "{{dictionary.profileNames.technician}} no puede realizar este tipo de {{dictionary.commonTerms.service}}",
  userHasOverlaps: "{{dictionary.profileNames.technician}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario",
  userNotAssignedToHeadquarter: "{{dictionary.profileNames.technician}} no está asignado a {{dictionary.commonTerms.headquarter}}",
  userNotAssignedToX: "{{dictionary.profileNames.technician}} no está asignado a {{x}}",
  userNotAvailable: "{{dictionary.profileNames.technician}} no está disponible en el horario seleccionado",
  userType: "Tipo de Usuario",
  userTypeConfigBase: "Tipo de usuario y configuración base",
  userTypeDescriptiontitle: "Ten en cuenta que",
  userTypeInterestedDescription: " El usuario {{dictionary.profileNames.interested}}  utiliza la plataforma web y solo se le permite la visualización de reportes",
  userTypeOperatorDescription: "El usuario {{dictionary.profileNames.operator}} utiliza la plataforma web y se enfoca en el agendamiento y seguimiento de Citas",
  userTypeRequired: "tipo de usuario es obligatorio",
  userTypeTechnicianDescription: "El usuario {{dictionary.profileNames.technician}} se encarga de realizar/atender {{dictionary.commonTerms.servicePlural}} Dependiendo la configuración de tu cuenta puede utilizar la aplicación web o la aplicación móvil",
  username: "Nombre de usuario",
  usernameDescription: "El usuario deberá usar {{username}} para ingresar a la aplicación",
  usernameLabel: "Usuario o correo electrónico",
  usernamePlaceholder: "Escribe tu usuario o correo electrónico",
  usernameRequired: "Debes ingresar un nombre de usuario",
  usernameRequiredMessage: "Debes ingresar un usuario o correo electrónico",
  users: "Usuarios",
  validationRemindWhen: "El valor no puede ser menor o igual a 0",
  value: "Valor",
  valueLabel: "Valor",
  valueRequired: "El valor es obligatorio",
  variables: "Variables",
  variablesDescription: "Habilitar variables a este tipo de atención",
  video: "Video",
  viewAccounts: "Ver cuentas",
  viewCustomers: "Visualizar {{dictionary.commonTerms.customerPlural}}",
  viewCustomersText: "El usuario podrá ver el listado de {{dictionary.commonTerms.customerPlural}} y seleccionar uno de acuerdo con su prefetencia. Selecciona NO únicamente si no tienes creado ningún {{dictionary.commonTerms.customer}}.",
  viewDetails: "Ver detalles",
  viewMore: "Ver más",
  viewSurvey: "Visualizar Encuesta",
  viewUsers: "Visualizar {{dictionary.profileNames.technicianPlural}}",
  viewUsersText: "El usuario podrá ver el listado de {{dictionary.profileNames.technicianPlural}} y seleccionar de acuerdo a su preferencia. Si seleccionas NO el usuario no verá el listado de {{dictionary.profileNames.technicianPlural}} y en ese caso se asignará de forma aleatoria uno que esté disponible.",
  waitingForResponse: "Esperando Respuesta",
  webhookMessage: "En Meta necesitas también ingresar el webhook. Recuerda que es el siguiente:",
  wednesday: "Miércoles",
  week: "Semana",
  weeks: "Semanas",
  welcomeText: "¡Bienvenido! Diligencia la siguiente información para crear tu cuenta",
  whatsapp: "Whatsapp",
  whatsappScheulding: "Permitir agendamiento por Whatsapp",
  widgetsExternal: "Widgets externos",
  willBeAssigned: "Se asignarán ",
  workField: "Trabajo en campo",
  workflow: "Flujo de trabajo",
  writeAMessage: "Escribe un mensaje",
  xNotAssignedToY: "{{x}} no está asignado a {{y}}",
  years: "Años",
  yes: "Si",
  yourAppointmentWillBeConfirmed: "Tu cita será confirmada"
};
