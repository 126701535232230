import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class CompaniesService {
  static async getAll({ search, idServiceType, page, isServiceCreation, idHeadquarter }) {
    const queryParams = {
      search,
      idServiceType,
      page,
      isServiceCreation: Boolean(isServiceCreation),
      idHeadquarter,
    };
    const { data } = await DataService.get(API.companies.root, queryParams);
    return {
      companies: data.companies,
      page: data.page,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async getById(id) {
    const { data } = await DataService.get(API.companies.root + id);
    return data.company;
  }

  static async createCompany(companyData) {
    const bodyParams = {
      nit: companyData.nit,
      name: companyData.name,
      phone: companyData.phone,
      address: companyData.address,
      mainContactName: companyData.mainContactName,
      email: companyData.email,
      legalName: companyData.legalName,
      email2: companyData.email2,
    };
    const { data } = await DataService.post(API.companies.root, bodyParams);
    return {
      company: data.company,
      defaultContact: data.defaultContact,
    };
  }

  static async updateCompany(id, companyData) {
    const updateData = {
      nit: companyData.nit,
      name: companyData.name,
      phone: companyData.phone,
      address: companyData.address,
      legalName: companyData.legalName,
      email: companyData.email,
      email2: companyData.email2,
    };
    const { data } = await DataService.patch(API.companies.root + id, updateData);
    return {
      company: data.company,
      defaultContact: data.defaultContact,
    };
  }

  static async removeCompany({ idCompany }) {
    const { data } = await DataService.delete(`${API.companies.root}/${idCompany}`);
    return data;
  }

  static async getAllContacts({ search, idCompany, page }) {
    const { data } = await DataService.get(`${API.companies.root}/${idCompany}/${API.companies.contacts}/`, {
      search,
      page,
    });
    return {
      contacts: data.contacts,
      pageSize: data.pageSize,
      total: data.total,
    };
  }

  static async getContactById({ idContact, idCompany }) {
    const { data } = await DataService.get(`${API.companies.root}/${idCompany}/${API.companies.contacts}/${idContact}`);
    return data.contact;
  }

  static async createContact({ idCompany, contactData }) {
    const body = {
      name: contactData.name,
      reference: contactData.reference,
      address: contactData.address,
      identification: contactData.identification,
      phone: contactData.phone,
      email: contactData.email,
    };
    const { data } = await DataService.post(`${API.companies.root}/${idCompany}/${API.companies.contacts}/`, body);
    return data.contact;
  }

  static async updateContact({ idCompany, contactData, idContact }) {
    const body = {
      name: contactData.name,
      reference: contactData.reference,
      address: contactData.address,
      identification: contactData.identification,
      phone: contactData.phone,
      email: contactData.email,
    };
    const { data } = await DataService.patch(
      `${API.companies.root}/${idCompany}/${API.companies.contacts}/${idContact}`,
      body,
    );
    return data.contact;
  }

  static async removeContact({ idContact, idCompany }) {
    const { data } = await DataService.delete(
      `${API.companies.root}/${idCompany}/${API.companies.contacts}/${idContact}`,
    );
    return data.contact;
  }

  static async getServiceTypeAssignations({ idCompany }) {
    const { data } = await DataService.get(
      `${API.companies.root}/${idCompany}/${API.companies.serviceTypeAssignations}`,
    );
    return {
      isUsingAllServiceType: data.isUsingAllServiceType,
      serviceTypes: data.serviceTypes,
    };
  }

  static async saveServiceTypeAssignations({ idCompany, isUsingAllServiceType, serviceTypesIds }) {
    const params = {
      isUsingAllServiceType: isUsingAllServiceType ?? false,
      serviceTypesIds: serviceTypesIds ?? [],
    };
    const { data } = await DataService.post(
      `${API.companies.root}/${idCompany}/${API.companies.serviceTypeAssignations}`,
      params,
    );
    return {
      isUsingAllServiceType: data.isUsingAllServiceType,
      serviceTypesIds: data.serviceTypesIds,
    };
  }
}

export { CompaniesService };
