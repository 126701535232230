import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Admin from '.';
import ProtectedRoute from '../../components/utilities/protectedRoute';
import { useAppRoutes } from '../../hooks/useAppRoutes';
import PendingsBoard from '../../views/pendingsBoard/PendingsBoard'

const AuthenticatedApp = () => {
  const { defaultRoute } = useAppRoutes();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
        <Route path="/pendings-board" component={PendingsBoard} />
        <ProtectedRoute path="/" component={Admin} />
        <Redirect to={defaultRoute} />
      </Switch>
    </Router>
  );
};

export default AuthenticatedApp;
