export const TRANSLATIONS_EN = {
  accept: "Accept",
  accepted: "Accepted",
  acceptedBy: "Accepted by:",
  acceptedDateOverview: "Accepted date: ",
  acceptedService: "Acceptation {{dictionary.commonTerms.service}}",
  account: "account",
  accountType: "Account Type",
  accounts: "Accounts",
  actions: "Actions",
  activateExtendForm: "enable extended form",
  add: "Add",
  addBlockedDays: "Add blocked days",
  addItem: "Add Item",
  addListItem: "Add list item",
  addPatientList: "Add {{dictionary.commonTerms.finalCustomer}} to the list",
  addService: "Add",
  addVariable: "+Add",
  address: "Address",
  addressIsRequired: "Address is required",
  addressPlaceholder: "Enter an address",
  addressRequired: "Address is required",
  admin: "{{dictionary.profileNames.admin}}",
  administrateServices: "Allows assigning {{dictionary.commonTerms.servicePlural}}",
  administrateTask: "Allows viewing the Task menu",
  administrateWorkflows: "Allows viewing the Workflows menu",
  administration: "Administration",
  advancedAvailability: "Advanced availability",
  alertResolvedBy: "Alert resolved by: ",
  alertResolvedDate: "Alert resolved date: ",
  allPatients: "All {{dictionary.commonTerms.finalCustomerPlural}}",
  allowCreation: "You will create this {{dictionary.commonTerms.service}} without having assigned any specialist",
  allowToViewHistory: "allow To View History",
  allowedIncidetnTypes: "Types of {{dictionary.commonTerms.servicePlural}} allowed",
  api: "Api",
  apiAdminOptions: "API (management options)",
  apiCreate: "+ Create new Api key",
  apiMessage: "This is your Api key, Remember that it expires on",
  apiMessageWhatsapp: "This is your code or key. Use this to place it in the Meta configuration",
  appColorMobile: "Mobile app colors",
  appColorText: "Select a color scheme, these colors will be used for some sections or buttons of the mobile app.",
  appMovilColor: "App mobile colors",
  appPlaceholder: "Select a mobile form",
  apply: "Apply",
  appoinmentNoAvailable: "There is not available {{dictionary.commonTerms.servicePlural}} for this search criteria, please select other options and try again.",
  appointment: "Appointments",
  appointmentAssignation: "{{dictionary.commonTerms.service}} assignment",
  appointmentAssignmentMessage: "You are trying to assign the following {{dictionary.commonTerms.servicePlural}} to the {{dictionary.commonTerms.finalCustomer}}",
  appointmentAvailable: "Appointment available",
  appointmentCancelation: "{{dictionary.commonTerms.service}} cancelation",
  appointmentNotification: "I would like to receive notifications in case my appointment can be brought forward and I authorize them to contact me at my cell phone number",
  appointmentOpportunitiesReport: "{{dictionary.commonTerms.servicePlural}} opportunity report",
  appointmentRepeat: "Repeat this{{dictionary.commonTerms.service}}",
  appointmentScheduledSuccessfully: "Your appointment has been scheduled successfully",
  appointmentScheduledToDate: "To {{date}} at {{time}}",
  appointmentsOf: "Appointments of",
  areYouSure: "Are you sure?",
  assetHasOverlaps: "{{asset}} has a {{dictionary.commonTerms.service}} at the same time",
  assetNotAvailable: "{{dictionary.commonTerms.asset}} not available at this time",
  assetPlaceholder: "Select {{dictionary.commonTerms.asset}}",
  assetRequired: "It is required to assign an Asset when assigning Appointments",
  assetTypes: "{{dictionary.commonTerms.assetPlural}} types",
  assets: "Assets",
  assetsDescription: "Create a list of Assets that work like databases and can be used at forms, create {{dictionary.commonTerms.service}}, etc.",
  assetsGroup: "Select the group of Assets that corresponds to this type of Appointment",
  assigmentAppoinment: "Appointment scheduling for",
  assign: "Assigned",
  assignedDate: "Assigned date",
  assignedDateOverview: "Asignation Date: ",
  assignedService: "Assignation of {{dictionary.commonTerms.service}}",
  assignedTo: "Assigned To: ",
  assignments: "assignments",
  attachmentWillBeRemoved: "The attachment, \"{{filename}}\" will be removed",
  audio: "Audio",
  autoCancelService: "Automatically cancel the service",
  automaticNotification: "Automatic notification",
  availability: "Configure Availability",
  availabilityDesccription: "Configure the availability of your Resources under multiple parameters, like headquarter or type of {{dictionary.commonTerms.service}}",
  availabilityTitle: "Availability",
  available: "Available",
  awaitingList: "Awaiting List",
  awaitingListDescription: "Create a list with {{dictionary.commonTerms.finalCustomerPlural}} who have been waiting for {{dictionary.commonTerms.servicePlural}} assignment or advance current assignments.",
  bannerSizeXY: "Banner (Size {{x}} by {{y}})",
  basename: "basename",
  basenamePlaceholder: "enter basename",
  biologicalSex: "Biological Sex",
  biologicalSexPlaceholder: "Select a biological sex",
  biologicalSexRequired: "Biological sex is required",
  birthDate: "Birthdate",
  birthdatePlaceholder: "Enter a birthdate",
  birthdateRequired: "Birthdate is required",
  blackTheme: "Black",
  blockDays: "Block specific days",
  blockDaysdescription: "You can add blocks in the scheduling for specific days and hours",
  blockedDays: "Blocked Days",
  bloodType: "Blood type",
  bloodTypePlaceholder: "Select a blood type",
  bloodTypeRequired: "Blood type is required",
  blueTheme: "Blue",
  boardFields: "Board fields",
  calendar: "Calendar",
  calendarFilters: "Filter calendar",
  cancel: "Cancel",
  cancelAvailability: "You are trying to cancel the availability of",
  cancelServiceTime: "Automatically cancel the Service in case of exceeding the estimated time without being Terminated",
  cancelationDate: "Cancelation date: ",
  canceledService: "Cancelation {{dictionary.commonTerms.service}}",
  cancelingAvailabilityOf: "You are trying to cancel the availability of",
  cancelingAvailabilityOfCalendar: "are trying to cancel {{dictionary.commonTerms.servicePlural}} of",
  cancellationDate: "Date",
  cancellationReasson: "Cancellation reasson",
  cancellationReassons: "Cancellation reassons",
  cancelledBy: "cancelled By: ",
  cancelledService: "Allow offering {{dictionary.commonTerms.servicePlural}} after cancellation",
  cantBeEmpty: "Can't be empty",
  cantHaveSpaces: "Can't have spaces",
  cantSaveRecord: "No se puede guardar el registro",
  changesWillNotBeSaved: "Changes will not be saved",
  characterText: "of {{n}} characters",
  chatCancelled: "Chat Cancelled",
  cityPlaceholder: "Select residence city",
  clear: "Clear",
  clickOrDragToUpload: "Click or drag to this area to upload a file",
  collaborators: "Collaborators",
  color: "Color",
  colorApplication: "Aplication Color",
  colorMark: "Color Marking",
  colorPlaceholder: "Enter Color",
  colorRequired: "You need to select a color.",
  compactView: "Compact view",
  company: "Company",
  companyAddress: "Company address",
  completeMFService: "Completed the Mobile Form",
  completeName: "Full Name",
  completeRFService: "Completed the Review Form",
  completedFormMobileBy: "Mobile form completed by: ",
  completedFormMobileDate: "Mobile form completed date: ",
  completedFormReviewBy: "Form completed by: ",
  completedFormReviewDate: "Form completed date: ",
  config: "Config",
  configUserMessage: "Select the type of user, enter the basic information and assign a password",
  configuration: "Configuration",
  confirm: "Confirm",
  confirmPassword: "confirm password",
  confirmation: "Confirmation",
  confirmedByFinalCustomer: "Confirmed by {{dictionary.commonTerms.finalCustomer}}",
  confirmedByFinalCustomerDate: "Confirmed Date {{dictionary.commonTerms.finalCustomer}}: ",
  confirmedByUser: "Confirmed",
  confirmedService: "Confirmed {{dictionary.commonTerms.service}}",
  confirmedServiceDescription: "The {{dictionary.commonTerms.service}} will be marked as confirmed",
  contact: "{{dictionary.commonTerms.contact}}",
  contactAddress: "{{dictionary.commonTerms.contact}} address",
  contactPhone: "{{dictionary.commonTerms.contact}} phone",
  contactPlaceholder: "Select {{dictionary.commonTerms.contact}}",
  contactReference: "{{dictionary.commonTerms.contact}} reference",
  contactRequired: "{{dictionary.commonTerms.contact}} is required",
  contacts: "{{dictionary.commonTerms.contactPlural}}",
  continue: "Continue",
  couldNotUploadFile: "Could not upload file: {{filename}}",
  country: "Country",
  countryPlaceholder: "Select a country",
  countryRequired: "must select a country",
  create: "Create",
  createAccount: "Create Account",
  createAppointment: "Create {{dictionary.commonTerms.service}}",
  createAssetType: "Create asset type",
  createAvailability: "New Availability",
  createCancellationReasson: "Create cancellation reasson",
  createContact: "Create {{dictionary.commonTerms.contact}}",
  createCustomer: "Create {{dictionary.commonTerms.customer}}",
  createDiary: "Agenda Creation",
  createDictionary: "Create Dictionary",
  createFinalCustomer: "Create {{dictionary.commonTerms.finalCustomer}}",
  createFinalCustomerTag: "Create Tags for {{dictionary.commonTerms.finalCustomer}}",
  createHeadquarter: "Create {{dictionary.commonTerms.headquarter}}",
  createHere: "You can create in this link.",
  createKey: "+ Create new key",
  createList: "Create List",
  createListNode: "Create List Node",
  createNode: "Create Node",
  createNote: "Create {{dictionary.commonTerms.note}}",
  createNotes: "Create {{dictionary.commonTerms.notePlural}}",
  createNotesType: "Create {{dictionary.commonTerms.note}} type",
  createNotification: "Create notification",
  createService: "Create {{dictionary.commonTerms.service}}",
  createServicesFromMobile: "Create services from mobile",
  createSkill: "Create skill",
  createUser: "Create user",
  created: "Created!",
  createdBy: "Creado By: ",
  createdRecords: "Records created",
  createdService: "Created {{dictionary.commonTerms.service}}",
  creationDate: "Creation date",
  creationOfRequests: "Creation of request",
  creationOfRequestsDescription: "If you enable this option, the interested user will be able to create a request for a {{dictionary.commonTerms.service}}, he will remain with the Requested status and will require an administrator or {{dictionary.profileNames.operator}} to assign a {{dictionary.profileNames.technician}} and define the {{dictionary.commonTerms.service}} Type",
  creationType: "Creation Type",
  creationTypePlaceholder: "Select Scheduling Type",
  creator: "Creator",
  currentValue: "Current value",
  custom: "Custom",
  customFieldPlaceholder: "Enter {{label}}",
  customFooterImage: "Use a custom image to display in the page footer. The image size should be 782px x 80px (Width - Height)",
  customHeaderImage: "Use a custom image to display in the page header. The image size should be 782px x 80px (Width - Height)",
  customImageForReports: "Use a custom image to display in the reports. The image size should be 360px x 135px (Width - Height)",
  customList: "Custom list",
  customLists: "Custom lists",
  customPdfDescription: "If you enable this option, the PDFs generated from the information of the appointment types will appear according to the custom PDF configuration",
  customer: "{{dictionary.commonTerms.customer}}",
  customerLocation: "{{dictionary.commonTerms.customer}} location",
  customerName: "{{dictionary.commonTerms.customer}} Name",
  customerPhone: "{{dictionary.commonTerms.customer}} phone",
  customerPlaceholder: "Select {{dictionary.commonTerms.customer}}",
  customerRequired: "{{dictionary.commonTerms.customer}} is required",
  customers: "{{dictionary.commonTerms.customerPlural}}",
  customersDescription: " If your institution management {{dictionary.commonTerms.customerPlural}} list, you can select the specific {{dictionary.commonTerms.customer}} to create the {{dictionary.commonTerms.service}}",
  customizationAdminOptions: "Customization (management options)",
  dashboard: "Dashboard",
  dataPolicyMessage: "Data policy",
  dataTreatment: "Data treatment",
  dateAndHour: "Date/Hour",
  day: "Day",
  days: "Days",
  decimalError: "Only accepted integer values",
  delete: "Delete",
  departamentPlaceholder: "Select residence department",
  departmentPlaceholder: "Select a residence department",
  description: "description",
  descriptionErrorNotification: "You already have a notification with this incorrect configuration",
  descriptionOrComment: " Description or coment",
  descriptionPlaceholder: "enter description",
  descriptionSelectServiceTypeClient: "Select the types of {{dictionary.commonTerms.service}} for this {{dictionary.commonTerms.customer}} ",
  descriptionSelfScheduling: "Enable a link to allow your {{dictionary.commonTerms.finalCustomerPlural}} to schedule {{dictionary.commonTerms.servicePlural}} on their own",
  destination: "Destination",
  dictionary: "Dictionary",
  doctor: "Doctor",
  documentType: "Document type",
  documentTypePlaceholder: "Select document type",
  documentTypeRequired: "Document type is required",
  download: "Download",
  downloadBaseTemplate: "Download base template to import lists",
  downloadImportsTemplateFile: "Download base template file for imports",
  downloadTemplate: "Download template",
  doyouAgree: "Do you agree?",
  duration: "Duration",
  earrings: "Earrings",
  earringsBoard: "Earrings board",
  earringsBoardDescription: "View a dashboard with a {{dictionary.commonTerms.servicePlural}} pending list ",
  edit: "Edit",
  editAccount: "Edit account",
  editAvailability: "Edit Availability",
  editCancellationReasson: "Edit cancellation reasson",
  editContact: "Edit {{dictionary.commonTerms.contact}}",
  editCustomer: "Edit {{dictionary.commonTerms.customer}}",
  editDictionary: "Edit Dictionary",
  editFinalCustomer: "Edit {{dictionary.commonTerms.finalCustomer}}",
  editFinalCustomerTag: "Edit Tag for {{dictionary.commonTerms.finalCustomer}}",
  editHeadquarter: "Edit {{dictionary.commonTerms.headquarter}}",
  editIncidentType: "Edit {{dictionary.commonTerms.service}} type",
  editInfoCustomers: "Can modify information of {{dictionary.commonTerms.customerPlural}}",
  editMainPage: "Edit main page",
  editNotesType: "Edit notes type",
  editNotification: "Edit notification",
  editService: "Edit {{dictionary.commonTerms.service}}",
  editSkill: "Edit skill",
  editState: "Edit State",
  editSurvey: "Edit Survey",
  editUser: "Edit User",
  editVariable: "Edit variable",
  email: "Email address",
  emailExample: "hello@netuxtecnologia.com",
  emailMenu: "Email",
  emailPlaceholder: "Enter email address",
  emailRequired: "Enter is required",
  emergency: "Emergencies",
  emergencyContact: "Emergency Contact:",
  emergencyWork: "Field Care or",
  employee: "Employee",
  employeePlural: "Employees",
  enableAvailability: "Enable Availability",
  enableCustomPdf: "Enable Custom PDF",
  enableForm: "Enable review form.",
  enableSelfscheulding: "Enable this availability for web self-scheduling",
  enableServiceTypeForSelfScheduling: "Enable {{dictionary.commonTerms.service}} type for self scheduling",
  enableServiceTypesClient: "Do you want to enable all types of {{dictionary.commonTerms.service}} for this {{dictionary.commonTerms.customer}}?",
  enabledUsers: "Enabled Users",
  endDate: "End date",
  endHour: "End hour",
  english: "English",
  enterDateList: "List entry date",
  enterNote: "Enter {{dictionary.commonTerms.note}}",
  enterSession: "Enter",
  enterSubject: "Enter the email subject",
  enterYourData: "Enter your data",
  enterpriseName: "Company Name",
  enterprisePlaceholder: "Netux S.A.S",
  entityName: "Entity name",
  eps: "Eps",
  error: "Error",
  errorCancelingService: "Error canceling {{dictionary.commonTerms.service}}",
  errorCreateMessage: "An error occurred while creating message",
  errorCreatingRecord: "There was an error creating the record",
  errorDeleteSkill: "An error occurred while trying to delete the record",
  errorFinishingService: "Error canceling {{dictionary.commonTerms.service}}",
  errorRecordAlreadyExists: "Record already exists",
  errorRecordNotFound: "Record not found",
  errorRemovingRecord: "There was an error removing the record",
  errorRetrievingColors: "There was an error retrieving colors",
  errorRetrievingRecord: "There was an error retrieving the record",
  errorRetrievingRecords: "There was an error retrieving the records",
  errorUpdatingRecord: "There was an error updating the record",
  errorUploadingFile: "There was an error uploading the file",
  estimatedTime: "Estimated time to automatically cancel the service (minutes):",
  executionDate: "Execution date",
  executionTime: "Execution time",
  exportToPdf: "Exportable to PDF",
  extendForm: "With this web form you can extend the information required for the creation of a task",
  extendedWebForm: "Extended Web Form",
  extendedWebFormParagraph: "With this web form you can extend the information required for self-scheulding",
  extension: "{{dictionary.commonTerms.extension}}",
  extensionPlural: "{{dictionary.commonTerms.extensionPlural}}",
  field: "Field",
  fieldIsRequired: "This field is required",
  fields: "Fields",
  fileMustBeImage: "File must be an image (jpeg or png)",
  fileSizeExceeded: "File size exceeded {{size}}MB",
  fileTypeNotSupported: "File type not supported",
  filesUploadingWillNotBeSaved: "Files that are uploading will not be saved",
  filesWithErrorWillNotBeSaved: "Files with error will not be saved",
  filterByServiceType: "Filter by {{dictionary.commonTerms.service}} type",
  filterByTechnician: "Filter by {{dictionary.profileNames.technician}}",
  filterServices: "Filter {{dictionary.commonTerms.servicePlural}}",
  filters: "Filters",
  finalCustomer: "{{dictionary.commonTerms.finalCustomer}}",
  finalCustomerAddress: "{{dictionary.commonTerms.finalCustomer}} address",
  finalCustomerEmail: "{{dictionary.commonTerms.finalCustomer}} email",
  finalCustomerIdentification: "{{dictionary.commonTerms.finalCustomer}} identification",
  finalCustomerInfo: "{{dictionary.commonTerms.finalCustomer}} info",
  finalCustomerMobileNumber: "{{dictionary.commonTerms.finalCustomer}} mobile number",
  finalCustomerName: "{{dictionary.commonTerms.finalCustomer}} name",
  finalCustomerPhone: "{{dictionary.commonTerms.finalCustomer}} phone",
  finalCustomerPlaceholder: "Select {{dictionary.commonTerms.finalCustomer}}",
  finalCustomerRequired: "{{dictionary.commonTerms.finalCustomer}} is required",
  finalCustomerTags: "{{dictionary.commonTerms.finalCustomer}} Tags",
  finalCustomers: "{{dictionary.commonTerms.finalCustomerPlural}}",
  finalCustomersDescription: "If your organization uses {{dictionary.commonTerms.finalCustomer}} list, you can select the specific {{dictionary.commonTerms.finalCustomer}} when you create a {{dictionary.commonTerms.service}}.",
  finalDate: "Final date",
  finalEdition: "enable editing after service is finished",
  finish: "Finish",
  finishByFinalCustomerService: "Finished by {{dictionary.commonTerms.finalCustomer}}",
  finishDate: "Finish date",
  finishService: "Finished {{dictionary.commonTerms.service}}",
  finishTime: "Finish Time",
  finishTimeRequired: "Finish Time required",
  finished: "Finished",
  finishedBy: "Finished By:",
  finishedRetries: "Finished Retries",
  formPlaceholder: "Select a form",
  friday: "Friday",
  from: "from",
  fromNAvailable: "from {{n}} available",
  fuchsiaTheme: "Magenta",
  fullView: "Full view",
  futureReminder: "Future reminder",
  generalInfo: "General Information",
  generalOptions: "General options",
  generateLinkText: "Generate links by specific {{dictionary.profileNames.technician}}",
  generateReport: "Generate Report",
  goBack: "Go back",
  grayTheme: "Gray",
  greenTheme: "Green",
  headquarter: "{{dictionary.commonTerms.headquarter}}",
  headquarterAddress: "{{dictionary.commonTerms.headquarter}} address",
  headquarterDefault: "General {{dictionary.commonTerms.headquarter}}",
  headquarterDescription: "Select the {{dictionary.commonTerms.headquarter}} to which this {{dictionary.profileNames.operator}} belongs",
  headquarterDescriptionTechnician: "Select the {{dictionary.commonTerms.headquarter}} to which this {{dictionary.profileNames.technician}} belongs",
  headquarterName: "{{dictionary.commonTerms.headquarter}} name",
  headquarterPlaceholder: "Select {{dictionary.commonTerms.headquarter}}",
  headquarters: "{{dictionary.commonTerms.headquarterPlural}}",
  healthInsurance: "Health insurance",
  healthInsuranceCode: "Health insurance code",
  healthInsurancePlaceholder: "Select a health insurance",
  healthInsuranceRequired: "Health insurance is required",
  here: "HERE",
  highPriority: "{{dictionary.commonTerms.highPriority}}",
  hourRequired: "Hour is required",
  hours: "Hours",
  hoursavailability: "Hours and availability",
  hoursdescription: "If you enable schedules for the user, you will not be able to assign services at times that are outside their schedulesof availability",
  howOften: "How often",
  howOftenPlaceholder: "Select how often",
  htmlEditorDescription: "Edit in HTML",
  idWebFormRequired: "The form is required",
  identification: "Identification",
  identificationAlreadyExists: "Identification already exists",
  identificationPlaceholder: "Enter identification",
  identificationRequired: "Identification is required",
  identificationandScearchRequired: "You must search",
  identifier: "Identifier",
  idiom: "Idiom",
  image: "Image",
  imageDimensionsError: "Image dimensions must be {{width}}px by {{height}}px",
  import: "Import",
  importCustomList: "Import custom list",
  importCustomListText: "Download template",
  importCustomListTextModal: "to fill out the information and then carry out the importation clicking on the following button.",
  importFinalCustomers: "Import {{dictionary.commonTerms.finalCustomerPlural}}",
  importFinalCustomersResult: "{{dictionary.commonTerms.finalCustomerPlural}} import result",
  importListItems: "Impport list items",
  incidentTypeCreate: "Create {{dictionary.commonTerms.service}} Type",
  incidentTypeEdit: "Edit {{dictionary.commonTerms.service}} Type",
  incidentTypeText: "Create {{dictionary.commonTerms.service}} type or edit {{dictionary.commonTerms.service}} type as needed.",
  includeInOpportunitiesReport: "Include on {{dictionary.commonTerms.servicePlural}} opportunity report",
  info: "Info",
  infoMessageWhatsapp: "This information will not be part of the message, it will be used to later identify in the history what type of message you sent",
  infoPatien: "Information the {{dictionary.commonTerms.finalCustomer}}",
  infoRequired: "This information is required",
  infoServiceStatus: "Can modify the state of {{dictionary.commonTerms.service}}",
  infoServiceStatusFromMobile: "allow modifying the status of web {{dictionary.commonTerms.service}}",
  infoSwitchAvailability: "You can modify advanced availability",
  initialDate: "Initial date",
  inspectFormService: "With this form you can make a final review of your service",
  integrationWhatsapp: "Integration Whatsapp",
  interested: "{{dictionary.profileNames.interested}}",
  isNotAValidEmail: "Not a valid email",
  itemNumberN: "Item #{{n}}",
  itemWillBeRemoved: "This item will be removed",
  keyInvalid: "Invalid identifier",
  keyLabel: "identifier",
  keyRequired: "Identifier is required",
  landlinePhone: "Landline phone",
  landlinePhoneNumberPlaceholder: "Enter phone number",
  landlinePhonePlaceholder: "Enter a landline phone",
  landlinePhoneRequired: "Landline phone is required",
  language: "Language",
  languageRequired: "The language is required",
  languageText: "Select a language",
  lastLocation: "Last location",
  lastname1: "First lastname",
  lastname1Placeholder: "Enter first lastname",
  lastname1Required: "First lastname is required",
  lastname2: "Second lastname",
  lastname2Placeholder: "Enter second lastname",
  lastname2Required: "Second lastname is required",
  legal: "Legal",
  legalName: 'Legal name',
  legalNamePlaceholder: "Enter legal name",
  legalSize: "Legal size",
  length: "Length",
  lengthMaxValidation: "{{label}} must have {{max}} chars at most",
  lengthMinValidation: "{{label}} must have {{min}} chars at least",
  lessThanOrEqualValidation: "{{label}} must be less than or equal to {{max}}",
  letterSize: "Letter size",
  lettersAndNumbersOnly: "Only letters and numbers are allowed",
  limitCharacters: "you exceeded the character limit",
  limitSelfScheduling: "limit auto programming period",
  limitSelfSchedulingDescription: "Limit the future period enabled on the selfscheduling page when selecting {{dictionary.commonTerms.servicePlural}}",
  link: "Link",
  linkCreateHeadquarter: "you can create more {{dictionary.commonTerms.headquarterPlural}}",
  linkCreateSkills: " you can create more Skills.",
  links: "Links",
  list: "List",
  loading: "Loading...",
  loadingItems: "Loading {{itemsName}}...",
  loginAction: "Log In",
  loginTitle: "Log In",
  logo: "logo",
  logoSizeXY: "Logo (Size {{x}} by {{y}})",
  logoText: "Upload your logo in .PNG format to appear in the top-left corner inside the web platform. Mandatory size (Width - Height) 400 x 80.",
  lowPriority: "{{dictionary.commonTerms.lowPriority}}",
  mainContact: "Main {{dictionary.commonTerms.contact}} name",
  mainContactPlaceholder: "Enter main {{dictionary.commonTerms.contact}} name",
  mainContactRequired: "Main {{dictionary.commonTerms.contact}} name is required",
  mainPageInstructions: "Main page instructions",
  mainTitle: "Main title",
  map: "Mapa",
  maxDifferenceIs1Month: "Max difference between dates is 1 month",
  medic: "Medic",
  medicPlural: "Medics",
  mediumPriority: "{{dictionary.commonTerms.mediumPriority}}",
  mentionedAssignments: "All mentioned assignments will",
  message: "Mensaje",
  messageAttempts: "An attempt will be made to send a message to {{n}} {{dictionary.commonTerms.finalCustomerPlural}} ",
  messageDataTerms: "of data and the ",
  messageDeleteEarring: "The rescheduling will be skipped and removed from the pending list",
  messageDeletePendingIncident: "The rescheduling will be skipped and removed from the pending list",
  messageRequired: "The message is mandatory.",
  messageTermsAndConditions: "I have read and accept the ",
  messengerService: "Messaging",
  messengerServiceDescription: "Send personalized messages and emails to groups or segments of Patients directly, or after compliance with some rules",
  messengerServiceWhatsapp: "Messaging: Whatsapp",
  minDateError: "Min date is {{minDate}}",
  minRotationTime: "The minimum rotation time is {{n}} minutes",
  minTimeError: "Min time is {{minTime}}",
  minutes: "Minutes",
  mobileAppColor: "App mobile colors",
  mobileAppDescription: "If your entity uses the mobile application, you can configure some user options",
  mobileAppModuleDescription: "If your entity uses the mobile application, you can configure some user options.",
  mobileAppModuleText: "Mobile App Settings",
  mobileForm: "Mobile Form",
  mobileFormLink: "You can create or edit with the necesary fields.",
  mobileFormRequired: "Please select a mobile form",
  mobileLogo: "Mobile Logo",
  mobileLogoText: "Upload your logo in .PNG format so that it appears in the menu bar of the mobile application. Mandatory size (Width - Height) 270 x 100 pixels.",
  mobilePhone: "Mobile phone",
  mobilePhonePlaceholder: "Enter a mobile phone",
  mobilePhoneRequired: "enter the cell phone",
  modules: "Modules",
  monday: "Monday",
  months: "Months",
  moveIncident: "Move {{dictionary.commonTerms.service}}",
  moveIncidentDescription: "The following changes will be made to the {{dictionary.commonTerms.service}}",
  movedToPending: "They will be moved to the pending list to ensure their rescheduling.",
  nAppointments: "{{n}} {{dictionary.commonTerms.servicePlural}}",
  nAttachmentFiles: "{{n}} attachment files",
  nHours: "{{n}} hours",
  nMinutes: "{{n}} minutes",
  name: "Name",
  name1: "First name",
  name1Placeholder: "Enter first name",
  name1Required: "First name is required",
  name2: "Second name",
  name2Placeholder: "Enter second name",
  name2Required: "Second name is required",
  nameAccount: "AccountName",
  namePlaceholder: "Enter name",
  nameRequired: "Name is required",
  nameTheMessage: "Name the message",
  nationalityCountry: "Nationality country",
  nationalityCountryPlaceholder: "Select nationality country",
  nationalityCountryRequired: "Nationality country is required",
  newAccounts: "Create",
  newAppointment: "New {{dictionary.commonTerms.service}}",
  newNotification: "New notification",
  newValue: "New value",
  next: "Next",
  nit: "Nit",
  no: "No",
  noApiKeysGenerated: "No API keys have been generated",
  noApiKeysGeneratedWhatsapp: "A Whatsapp Key has not been generated",
  noComplete: "assign the status \"not complete\" if an alert has been triggered",
  noDataTreatmentPolicy: "There is no data treatment policy",
  noLegalTerms: "There are no legal terms",
  noScheduleTimesAvailable: "The selected {{dictionary.profileNames.technician}} does not avalibale schedules, please select another.",
  noSchedulesAdded: "No schedules have been added",
  noValue: "No value assigned",
  notAvailable: "Not available",
  notAvailanle: "Not available",
  notDelivered: "Not delivered",
  notImportedRecords: "Records that were not imported",
  notPrioritary: "{{dictionary.commonTerms.notPrioritary}}",
  note: "{{dictionary.commonTerms.note}}",
  notePlural: "{{dictionary.commonTerms.notePlural}}",
  noteTypes: "Type de {{dictionary.commonTerms.note}}",
  notes: "{{dictionary.commonTerms.notePlural}}",
  notesTypes: "Types de {{dictionary.commonTerms.notePlural}}",
  notification: "Notification",
  notificationEvent: "Event",
  notificationEventRequired: "Event type is required",
  notificationHour: "Notify at a specific time",
  notificationSent: "Notification sent",
  notificationType: "Notification type",
  notificationTypeRequired: "Notification type is required",
  notifications: "Notifications",
  notifyTo: "Notify to",
  notifyToRequired: "Mail recipient is required",
  number: "Number",
  obligatory: "Obligatory",
  of: "of",
  ok: "Ok",
  onlyNumbersValidation: "{{label}} must have only numeric chars",
  operator: "{{dictionary.profileNames.operator}}",
  optional: "(Optional)",
  optionalMobilePhone: "Optional mobile phone",
  optionalMobilePhonePlaceholder: "Enter an optional mobile phone",
  options: "Options",
  orangeTheme: "Orange",
  origin: "Origin",
  other: "Other",
  otherOptions: "Other options",
  others: "Others",
  pageFooterImage: "Page Footer Image",
  pageHeaderImage: "Page Header Image",
  paperType: "Paper type",
  passwordAndConfirmationsMustBeEquals: "Password confirmation does not match.",
  passwordDigits: "Password must contain {{n}} digits",
  passwordLabel: "Password",
  passwordLength: "Password must be at least {{n}} chars long",
  passwordLowercaseLetters: "Password must contain {{n}} lowercase letters",
  passwordPlaceholder: "Write your password",
  passwordRequiredMessage: "Password is required",
  passwordSpecialChars: "Password must contain {{n}} of these chars: {{acceptedChars}}",
  passwordUppercaseLetters: "Password must contain {{n}} uppercase letters",
  patiensHistoryAppointments: "{{dictionary.commonTerms.finalCustomerPlural}} that requested the following {{dictionary.commonTerms.servicePlural}} types",
  patientHasOverlaps: "The {{dictionary.commonTerms.finalCustomer}} has a {{dictionary.commonTerms.service}} in the same time",
  patientIdConfirmed: "Patient identification confirmed",
  patientIdRejected: "Patient identification rejected",
  pdfConfigDescription: "Customize partially or completely the PDF documents that are generated",
  pdfConfigText: "PDF Configuration",
  pdfFormat: "PDF Format",
  pdfFormatBottomBorder: "Bottom border (cm)",
  pdfFormatCopyHTMLContent: "Copy HTML content",
  pdfFormatLeftBorder: "Left border (cm)",
  pdfFormatPlaceholder: "Select a format",
  pdfFormatRightBorder: "Right border (cm)",
  pdfFormatTopBorder: "Top border (cm)",
  pdfFormatsCustom: "Custom",
  pdfFormatsStandard: "Standard",
  pdfSetting: "PDF Settings",
  pendingAssignments: "Pending for assignment.",
  permission: "Permissions",
  permissionDenied: "Permission denied",
  personInCharge: "Person in charge",
  personalName: "Personal Name",
  personalQuestion: "3. How do you name your staff?",
  personalization: "Personalization",
  petitionBy: "Petition created by: ",
  petitionDate: "Petition date created: ",
  phoneNumber: "Phone number",
  phoneNumberRequired: "Phone number is required",
  plural: "Plural",
  pluralPlaceholder: "Enter Plural",
  powerBy: "Powered by",
  prefix: "Prefix",
  prefixNotAvailable: "Prefix not available",
  prefixPlaceholder: "netux",
  prefixRequired: "Prefix is required",
  prefixText: "Enter a prefix or identifier",
  preview: "Previsualizar",
  previous: "Back",
  priority: "{{dictionary.commonTerms.priority}}",
  priorityPlaceholder: "select priority",
  priorityRequired: "Priority required",
  programming: "Programming",
  programmingDescription: "Schedule {{dictionary.commonTerms.service}} in advance to repeat multiple times in the future.",
  publicName: "Public name",
  publicNameRequired: "Public name is required",
  purpleTheme: "Purple",
  reCaptchaError: "Captcha error, please try again",
  recordWillBeRemoved: "The record: {{recordDescription}} will be removed",
  redTheme: "Red",
  reducedView: "Reduced view",
  reference: "Reference",
  referencePlaceholder: "Enter reference",
  referenceRequired: "Reference is required",
  regime: "Regime",
  regimePlaceholder: "Select a regimen",
  regimeRequired: "Regime is required",
  rejected: "Rejected",
  rejectedBy: "Rejected by: ",
  rejectedDate: "Rejected date: ",
  remainingAssignments: "Remaining assignments will be",
  remindWhen: "Remind when",
  remindWhenRequired: "Remind when is required",
  reminder: "Reminder",
  remove: "Remove",
  removeUserExist: "It is not possible to remove the {{dictionary.commonTerms.finalCustomer}}",
  removeUserExistDescription: "Because you have scheduled appointments",
  removed: "Removed!",
  repeatTimes: "¿how many times will it repeat?",
  repeatTimesPlaceholder: "Select number of times",
  report: "Report",
  reportFieldsConfig: "Configure Report Fields",
  reportLogo: "Report Logo",
  reports: "Reports",
  reprogramCountTranslation: "They will be rescheduled",
  reprogrammedCount: "will be rescheduled",
  reschedule: "Reschedule",
  rescheduleCount: "Will reschedule ",
  rescheduleCountTranslation: "They will be rescheduled",
  reschedulelater: "Reschedule later",
  reserveAnotherAppointment: "Schedule another {{dictionary.commonTerms.service}}",
  reserveAppointment: "Reserve {{dictionary.commonTerms.finalCustomer}}",
  residenceCity: "Residence city",
  residenceCityRequired: "Residence city is required",
  residenceDepartment: "Residence department",
  residenceDepartmentPlaceholder: "Select residence department",
  residenceDepartmentRequired: "Residence department is required",
  resource: "Resource",
  resourceDescription: "Select {{dictionary.profileNames.technicianPlural}} who can manage this {{dictionary.profileNames.operator}}",
  resourceManagement: "{{dictionary.profileNames.technicianPlural}} Management",
  resourceName: "{{dictionary.profileNames.technician}} Name",
  resourcePlaceholder: "Select resource",
  resources: "Resources",
  retried: "Retried",
  return: "Return",
  review: "{{dictionary.commonTerms.review}}",
  reviewPlural: "{{dictionary.commonTerms.reviewPlural}}",
  reviewYourAvailability: "Review your availability, the confirmation will be sent to your email",
  rotationTimeRequired: "Rotation time is required",
  rowInFile: "Row in file",
  saturday: "Saturday",
  save: "Save",
  scheduleLock: "Schedule lock",
  scheduleTimeNotAvailable: "Schedule time not available",
  scheduled: "Scheduled",
  scheduledAppointments: "The following {{dictionary.commonTerms.servicePlural}} are scheduled at that time:",
  search: "Search",
  searchAppointment: "Search appointment",
  searchAssignment: "Search assignment",
  searchChat: "Search chat",
  searchCustomerPlaceholder: "Enter name or identification",
  searchRequired: "You must do the search",
  searchSkillsPlaceholder: "Enter skills",
  searchSpecialist: "Search Specialist",
  secondaryEmail: "Secondary email",
  secondaryEmailPlaceholder: "Enter secondary email address",
  seeAllServices: "Allows viewing all types of {{dictionary.commonTerms.servicePlural}}",
  seeAllTask: "Allows viewing Tasks assigned to any Operator user",
  seeAllWorkflows: "Allows viewing Workflows assigned to any Operator user",
  selectAppointmentDate: "Select a day for the appointment",
  selectAppointmentTime: "Select time",
  selectAppointmentsTimes: "Select times for the appointment",
  selectAsset: "Select {{dictionary.commonTerms.asset}}",
  selectAtLeastOneDay: "Must select at least one day",
  selectCancellationReasson: "Select the reason for canceling the appointment",
  selectColorForExportableFormat: "Select a color to be used in the exportable format",
  selectEpsPlaceHolder: "Select eps",
  selectFieldsToDisplay: "Select the fields you want to display in the reports",
  selectFile: "Select file",
  selectForm: "select the form for the mobile application",
  selectFormat: "Select format",
  selectHeadquarter: "Select the {{dictionary.commonTerms.headquarterPlural}} that apply to this type of {{dictionary.commonTerms.service}}. Also,",
  selectPatienText: "Select the specific fields of the {{dictionary.commonTerms.finalCustomer}} that the customer must complete when self-scheduling",
  selectPreferredAction: "Select the preferred action to apply",
  selectSkills: "seleccione las habilidades que aplican para este tipo de servicio. Also",
  selectStatus: "Select a Status",
  selectTechnicianAndDate: "Select a  {{dictionary.profileNames.technician}} and a date to seek the possibility of reassignment:",
  selectTheAppointment: "Seleccione la cita",
  selectTypeUserPlaceholder: "Select the type of user",
  selectWebForm: "Select a Form",
  selectWhoYou: "Select who you will send your message to",
  selfScheduled: "selfScheduled",
  selfScheduling: "Self-Scedhuling",
  selfSchedulingAccessLink: "Self-Scedhuling Access Link",
  selfSchedulingInstructions: "Instructions for this type of {{dictionary.commonTerms.service}}",
  selfSchedulingLengthInstructions: "Define how long will it last, keep on mind that this time will determine the time to show on the self-scheduling page",
  selfSchedulingPublicNameInstructions: "This is the name that the users will see in the self-scheduling page",
  send: "Send",
  sent: "Sent",
  sentMessages: "Sent messages",
  service: "{{dictionary.commonTerms.service}}",
  serviceAssigned: "{{dictionary.commonTerms.service}} assigned",
  serviceAssignment: "Assignment of {{dictionary.commonTerms.servicePlural}}",
  serviceCanceled: "{{dictionary.commonTerms.service}} canceled",
  serviceConfirmation: "{{dictionary.commonTerms.service}} Confirmation",
  serviceConfrimation: "{{dictionary.commonTerms.service}} confirmation",
  serviceDate: "Date",
  serviceDatePlaceholder: "Enter a date",
  serviceDateRequired: "Date is required",
  serviceDescription: "Description",
  serviceDescriptionPlaceholder: "Enter description",
  serviceDescriptionRequired: "Description is required",
  serviceModified: "{{dictionary.commonTerms.service}} modified",
  serviceName: "{{dictionary.commonTerms.service}} name",
  serviceNamePlaceholder: "Enter {{dictionary.commonTerms.service}} name",
  serviceNameRequired: "{{dictionary.commonTerms.service}} name is required",
  serviceTime: "Time",
  serviceTimePlaceholder: "Enter a time",
  serviceTimeRequired: "Time is required",
  serviceType: "{{dictionary.commonTerms.service}} type",
  serviceTypeNotAssignedToHeadquarter: "The {{dictionary.commonTerms.service}} type is not assigned to the {{dictionary.commonTerms.headquarter}}",
  serviceTypeNotAssignedToX: "The {{dictionary.commonTerms.service}} type is not assigned to {{x}}",
  serviceTypePlaceholder: "Select a {{dictionary.commonTerms.service}} type",
  serviceTypeRequired: "{{dictionary.commonTerms.service}} type is required",
  serviceTypeText: "New {{dictionary.commonTerms.service}} type",
  serviceTypes: "{{dictionary.commonTerms.servicePlural}} type",
  serviceWillBeCanceled: "{{dictionary.commonTerms.service}} will be Canceled",
  serviceWillBeFinished: "{{dictionary.commonTerms.service}} will be finished",
  services: "{{dictionary.commonTerms.servicePlural}}",
  servicesByType: "{{dictionary.commonTerms.servicePlural}} by type",
  servicesCanceled: "{{dictionary.commonTerms.servicePlural}} canceled",
  servicesConfirmed: "{{dictionary.commonTerms.servicePlural}} confirmed",
  servicesCreated: "{{dictionary.commonTerms.servicePlural}} created",
  servicesHistory: "{{dictionary.commonTerms.servicePlural}} history",
  servicesLanding: "{{dictionary.commonTerms.servicePlural}} landing page",
  servicesPerDay: "{{dictionary.commonTerms.servicePlural}} per day",
  servicesSelfScheduled: "{{dictionary.commonTerms.servicePlural}} self-scheduled",
  servicesTypesAssigned: "Select the types of {{dictionary.commonTerms.servicePlural}} with permission to view",
  shippingDate: "Shipping date",
  shortDescription: "Descripción corta",
  signOut: "Sign out",
  singular: "Singular",
  singularPlaceholder: "Enter singular",
  skillPlaceholder: "Select the skills",
  skillWillBeRemoved: "This record will be completely removed.",
  skills: "{{dictionary.commonTerms.skills}}",
  skillsDescription: "Select Skills for this Resource. You can also create more Skills and associate them to the types ofService",
  skillsDescriptionFinish: "and associate them with {{dictionary.commonTerms.service}} types",
  skillsDescriptionLink: "You can also create more {{dictionary.commonTerms.skillsPlural}}",
  skillsRequired: "Please select at least one skill",
  spanish: "Spanish",
  specialist: "Specialist",
  specialistPlural: "Specialists",
  specificEmail: "Specific email",
  startDate: "Start date",
  startHour: "Start hour",
  startTime: "Start Time",
  startTimeRequired: "Start time required",
  startTimeShouldBeGreater: "Start time must be higher",
  states: "States",
  status: "Status",
  statusChangeNotAllowed: "Status change not allowed",
  statusRequired: " Status required",
  subject: "Subject",
  subjectRequired: "Subject is required",
  succesSendMessages: "Message created successfully",
  success: "Success",
  successCancelingService: "{{dictionary.commonTerms.service}} canceled successfully",
  successCreateToken: "Token generate successfully",
  successCreatingCancellation: "Cancellation created successfully",
  successCreatingRecord: "Record created successfully",
  successDeleteSkill: "Record successfully deleted",
  successFinishingService: "{{dictionary.commonTerms.service}} finished successfully",
  successRemoveService: "{{dictionary.commonTerms.service}} deleted successfully",
  successRemovingRecord: "Record removed successfully",
  successUpdatingRecord: "Record updated successfully",
  successUploadingFile: "File uploaded successfully",
  sunday: "Sunday",
  supportChat: "Support Chat",
  supportChatDescription: "Enable the support chat so that {{dictionary.commonTerms.finalCustomerPlural}} can communicate with the support team",
  surveys: "Surveys",
  synchronizedBy: "Synchronized By: ",
  synchronizedDate: "Synchronized Date: ",
  tagsPlaceholder: "Enter tags to {{dictionary.commonTerms.finalCustomer}}",
  task: "Tasks",
  technician: "{{dictionary.profileNames.technician}}",
  technicianPlaceholder: "Select {{dictionary.profileNames.technician}}",
  technicianPlural: "{{dictionary.profileNames.technicianPlural}}",
  technicianRequired: "{{dictionary.profileNames.technician}} is required",
  technicianScheduleOverlaps: "{{dictionary.profileNames.technician}} has assignations on this scheduled time",
  templateRequired: "Template is required",
  terms: "Terms",
  termsAndConditionsMessage: "terms and conditions",
  termsAndConditionsTitle: "Terms and conditions",
  textAccountuser: "To log into your account, you can use your username {{prefix}}.{{username}} and the password you entered",
  textCopyToClipBoard: "Text has been copied to clipboard",
  textGenerate: "a new apiKey has been generated",
  the: "the",
  theFollowingAppointments: "The following assignments are scheduled at that time:",
  theFollowingAppointmentsCalendar: "In this schedule the following assignments were found:",
  thisFieldIsRequired: "This field is required",
  thisRecordWillBeRemoved: "This record will be removed",
  thursday: "Thursday",
  time: "Time",
  timeEstimated: "Estimated time to cancel the service (Minutes): ",
  timeExecution: "Approximate execution time in minutes",
  timeFormat: "Time format",
  timeUnit: "Time unit",
  timeUnitPlaceholder: "Days, weeks, months, years",
  timeUnitPlaceholderPleace: "Select unit of time",
  timeUnitRequired: "Time unit is required",
  timeZone: "Time zone",
  timeZoneExample: "(UTC-05:00) Bogota, Lima, Quito",
  timeZoneText: "Select your time zone",
  timezoneRequired: "The time zone is required",
  titleCardselfScheduling: "Selfscheduling page",
  titleErrorNotification: "Unable to create this notification ",
  tittleIntegrationwhatsapp: "Whatsapp integration",
  to: "to",
  tokenMetaMessage: "Enter the permanent token generated by Meta",
  topic: "{{dictionary.commonTerms.topic}}",
  topicPlural: "{{dictionary.commonTerms.topicPlural}}",
  totalitems: "Total items",
  tuesday: "Tuesday",
  typeOfExam: "Type of exam",
  typeUsertext: "Select the type of user, enter basic information and assign the password",
  until: "Until",
  updateList: "Edit list",
  updateListNode: "Update node",
  updatePasswordText: "Change password",
  updated: "Updated",
  updatedBy: "Updated by: ",
  updatedDate: "Updated date: ",
  updatedMobileFormDate: "Mobile form updated date: ",
  updatedMobileFormdBy: "Mobile form updated by: ",
  updatedRecords: "Records updated",
  uploadImportsTemplateMessage: "Upload template completed",
  uploadTemplate: "Upload template",
  user: "User",
  userAdmin: "admin user",
  userAdminPlaceholder: "admin",
  userAdminRequired: "admin user is required",
  userCannotPerformService: "The {{dictionary.profileNames.technician}} cannot perform this type of {{dictionary.commonTerms.service}}",
  userHasOverlaps: "The {{dictionary.profileNames.technician}} has a {{dictionary.commonTerms.service}} at the same time",
  userNotAssignedToHeadquarter: "The {{dictionary.profileNames.technician}} is not assigned to the selected {{dictionary.commonTerms.headquarter}}",
  userNotAssignedToX: "The {{dictionary.profileNames.technician}} is not assigned to {{x}}",
  userNotAvailable: "The {{dictionary.profileNames.technician}} is not available at the selected time",
  userType: "User Type",
  userTypeConfigBase: "User type and base configuration",
  userTypeDescriptiontitle: "Have in point that",
  userTypeInterestedDescription: " The user {{dictionary.profileNames.interested}}  uses the web platform and can only view reports ",
  userTypeOperatorDescription: "The {{dictionary.profileNames.operator}} user uses the web platform and focuses on appointment scheduling and tracking",
  userTypeRequired: "userType is required",
  userTypeTechnicianDescription: "The user {{dictionary.profileNames.technician}} uses the mobile app and does field tasks",
  username: "username",
  usernameDescription: "the user must use {{username}} to login into the app ",
  usernameLabel: "Username or email",
  usernamePlaceholder: "Write your username or email",
  usernameRequired: "You must enter an username ",
  usernameRequiredMessage: "You must enter an username or email",
  users: "Users",
  validationRemindWhen: "Number cant be lower or equal to 0",
  value: "Value",
  valueLabel: "value",
  valueRequired: "value is required",
  variables: "Variables",
  variablesDescription: "Enable this kind of attention",
  video: "video",
  viewAccounts: "View accounts",
  viewCustomers: "View {{dictionary.commonTerms.customerPlural}}",
  viewCustomersText: "The user will be able to see the list of {{dictionary.commonTerms.customerPlural}} and select one according to their preference. Select NO only if you dont have any {{dictionary.commonTerms.customer}} created",
  viewDetails: "View details",
  viewMore: "View more",
  viewUsers: "View {{dictionary.profileNames.technicianPlural}}",
  viewUsersText: "The User can view the list of {{dictionary.profileNames.technicianPlural}} and choose as per his your preference. If your choose \"No\" option, the user cant see the list of {{dictionary.profileNames.technicianPlural}}, so a random and available {{dictionary.profileNames.technicianPlural}} is assigned.",
  waitingForResponse: "Waiting for response",
  webhookMessage: "In Meta you also need to enter the webhook. Remember that it is the following:",
  wednesday: "Wednesday",
  week: "Week",
  weeks: "Weeks",
  welcomeText: "Welcome! Fill out the following information to create your account",
  whatsapp: "Whatsapp",
  whatsappScheulding: "Allow scheduling by Whatsapp",
  widgetsExternal: "Widgets external",
  willBeAssigned: "Will assign ",
  workField: "Field Work",
  workflow: "Workflow",
  writeAMessage: "Write a message",
  xNotAssignedToY: "{{x}} is not assigned to {{y}}",
  years: "Yearas",
  yes: "Yes",
  yourAppointmentWillBeConfirmed: "Your appointment will be confirmed"
};
