const s3Url = process.env.REACT_APP_S3_URL;
const s3AppPath = `${s3Url}/${process.env.REACT_APP_S3_BUCKET}`
const servicesReportUrl = process.env.REACT_APP_STANDAR_REPORT_URL;

export default {
  s3Path: s3AppPath,
  patientsImportTemplate: `${s3Url}/micita-test/Plantilla%20importacion%20de%20pacientes.xlsx`,
  customListImportTemplate: `${s3Url}/micita-test/Plantilla%20importacion%20de%20listas.xlsx`,
  APPOINTMENTS_REPORT: `${servicesReportUrl}/micita/services_report`,
  DEFAULT_BANNER: `${s3Url}/assets/micitaAssets/default-self-scheduling-banner.png`,
  APPOINTMENT_OPPORTUNITIES_REPORT: `${servicesReportUrl}/micita/opportunities_report`
};
