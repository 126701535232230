import { useCallback, useEffect, useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import { IncidentsService } from '../../../services/incidentsService';
import { useTableData } from '../../../components/dataTable/hooks/useTableData';
import { incidentsStatus } from '../../../config/constants/incidentsStatus';
import { debounceFunction } from '../../../utility/utility';

export const usePendingsBoardTable = ({ rotationTime = 5 }) => {
  const { showErrorNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const { setTableData, ...tableData } = useTableData({
    total: 0,
    useQueryParams: true,
    page: 1,
    pageSize: 0,
    data: [],
  });
  const { page: tablePage, search: tableSearch = '', total: tableTotal, pageSize: tablePageSize } = tableData;

  const getData = useCallback(
    debounceFunction(async ({ page = 1, search } = {}) => {
      setIsLoading(true);
      try {
        const {
          services: data,
          pageSize,
          total,
        } = await IncidentsService.getAll({
          page,
          search,
          excludedStatus: [incidentsStatus.CANCELED, incidentsStatus.REJECTED, incidentsStatus.FINISH],
        });
        setTableData({
          total,
          pageSize,
          data,
          page,
          search,
        });
      } catch (error) {
        showErrorNotification({
          title: 'error',
          description: 'errorRetrievingRecords',
        });
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [setTableData, showErrorNotification],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const timeOut = setInterval(() => {
      const nextPage = tablePage * tablePageSize > tableTotal ? 1 : tablePage + 1;
      getData({ page: nextPage, search: tableSearch });
    }, rotationTime * 60000);
    return () => {
      clearInterval(timeOut);
    };
  }, [getData, tablePage, tableSearch, tableTotal, tablePageSize, rotationTime]);

  return {
    isLoading,
    getData,
    tableData,
  };
};
