import Styled from 'styled-components';

const FormWrapperPage = Styled.div`
    padding: 10px;
    .ant-input-number  {
        width: 100%;
    }
    .ant-form-item {
        padding: 10px;
    }
    .ant-picker {
        width: 100%;
        min-height: 46px;
        .ant-picker-input {
            padding-left: 12px;
        }
    }
`;

const FormWrapper = Styled(FormWrapperPage)`
    max-height: 400px;
    overflow-y: auto;
`;

const ForWrapperoffScroll = Styled.div`
max-height: 800px;
    overflow-x: auto;
`;

export { FormWrapper, FormWrapperPage, ForWrapperoffScroll };
