import { useState, useCallback } from 'react';
import { useTableData } from '../../../components/dataTable/hooks/useTableData';
import { useNotifications } from '../../../hooks/useNotifications';
import { UsersService } from '../../../services/usersService';

export const useTableUser = ({ selectedProfileType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setTableData, ...tableData } = useTableData({ useQueryParams: true });
  const { showErrorNotification } = useNotifications();

  const getData = useCallback(
    async ({ search, page }) => {
      setIsLoading(true);
      try {
        const { users, pageSize, total } = await UsersService.getAll({ search, page, idProfile: selectedProfileType });
        setTableData({
          data: users,
          total,
          search,
          page,
          pageSize,
        });
      } catch (error) {
        showErrorNotification({
          title: 'error',
          description: 'errorRetrievingRecords',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [setTableData, showErrorNotification, selectedProfileType],
  );

  const reloadData = () => {
    getData({ page: tableData.page, search: tableData.search });
  };

  const removeUser = (_id) => {
    return UsersService.removeUser(_id);
  };

  return {
    isLoading,
    reloadData,
    tableData,
    getData,
    removeUser,
  };
};
