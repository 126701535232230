import PropTypes from 'prop-types';
import { useMemo } from 'react';
import moment from 'moment';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { TagTable } from '../Style';
import { DataTable } from '../../../components/dataTable/data-table';

export const PendingsBoardTable = ({ tableData, isLoading, settingData }) => {
  const { translate: t } = useDictionaryTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('status'),
        show: settingData?.fields.status,
        width: '12%',
        render: (_, record) => (
          <>
            {record.infoStatus?.name.split(',').map((statusItem) => {
              let color;
              let textColor;
              if (statusItem === 'En Proceso') {
                color = 'processing';
                textColor = '#6ED2F7';
              } else if (statusItem === 'Asignado') {
                color = 'orange';
                textColor = '#fcab74';
              } else {
                color = 'red';
                textColor = 'red';
              }
              return (
                <TagTable color={color} key={statusItem} style={{ color: textColor }}>
                  {statusItem}
                </TagTable>
              );
            })}
          </>
        ),
      },
      {
        title: t('Id'),
        show: settingData?.fields.id,
        dataIndex: 'privateId',
        key: '1',
      },
      {
        title: t('serviceName'),
        show: settingData?.fields.name,
        dataIndex: 'name',
        key: '2',
      },
      {
        title: t('finalCustomer'),
        show: settingData?.fields.customer,
        render: (_, record) => record.infoFinalCustomer?.fullname,
        key: '3',
      },
      {
        title: t('serviceType'),
        show: settingData?.fields.serviceType,
        render: (_, record) => record.infoServiceType?.name,
        key: '4',
      },
      {
        title: t('dateAndHour'),
        show: settingData?.fields.assignedDate,
        render: (_, record) => moment(record.start).format('DD/MM/YYYY HH:mm'),
        dataIndex: 'start',
        key: '5',
      },
      {
        title: t('resource'),
        show: settingData?.fields.assignedUser,
        render: (_, record) => record.infoAssignedUser?.profile.name,
        key: '6',
      },
    ],
    [settingData],
  );

  return (
    <>
      <DataTable
        columns={columns.filter((column) => column.show)}
        isLoading={isLoading}
        tableData={tableData}
        showPagination={false}
        headerData={{
          show: false,
        }}
      />
    </>
  );
};

PendingsBoardTable.propTypes = {
  settingData: PropTypes.object,
  tableData: PropTypes.func,
  isLoading: PropTypes.bool,
};
