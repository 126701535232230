import { Form } from 'antd';
import Styled from 'styled-components';

const TableHeader = Styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
`;

const SearchForm = Styled(Form)`
  flex: 1;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  min-width: 80%;
`;

const SearchFormText = Styled.p`
  flex: 1;
  min-width: 150px;
  color: #686868;
  text-align: left;
  font-size: 1.3em;
  font-weight: 800;
  padding: 0px;
  margin: 0px;
  .small {
    font-weight: 400;
    font-size: 0.9em;
  }
`;

const SearchFormInputContainer = Styled.div`
  flex: 1 1 200px;
  min-width: 80%;
  padding-bottom: 8px;
  .ant-form-item {
    margin: 0px;
    border-bottom: solid 1px #cdcdcd;
  }
  .ant-btn-danger {
    background: none;
    color: red;
  }
  & input {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }
`;

  const SearchFormAction = Styled.div`
  flex: 1;
  padding-bottom: 8px;
  display: flex;
  align-items: right;
  gap: 9px;
    
  .contentExport{

      margin-left: auto; 
      display: flex;
      align-items: center;
  .icon{
    margin-Top:5px
    margin-Left:5px
  }

  }
`;

export { SearchForm, SearchFormText, SearchFormAction, SearchFormInputContainer, TableHeader };
