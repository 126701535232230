

export const pendingsBoardsColumns = {
  assignedDate: { name: 'assignedDate', },
  assignedUser: { name: 'assignedUser' },
  customer: { name: 'customer' },
  id: { name: 'id' },
  name: { name: 'name' },
  serviceType: { name: 'serviceType' },
  status: { name: 'status' },
} 