import React from 'react';
import { Aside, Container } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Container>
        <Aside>
          <div className="auth-side-content">
            <div className="login-background-top">
              <img alt="nx-bg-top" src={require('../../static/img/auth/MI CITA@2x.png')} />
            </div>
            <div className="login-illustration">
              <img alt="nx-img" src={require('../../static/img/auth/Illustration.png')} />
            </div>
            <div className="login-background-bottom">
              {/* <img alt="nx-bg-bottom" width="100%" src={require('../../static/img/auth/BG-bottom.png')} /> */}
            </div>
          </div>
        </Aside>
        <div className="main-content">
          <WraperContent />
        </div>
      </Container>
    );
  };
};

export default AuthLayout;
