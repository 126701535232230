import moment from 'moment';
import { API } from '../config/api';
import { incidentsStatus } from '../config/constants/incidentsStatus';
import { DataService } from '../config/dataService/dataService';
import { getDefaultDate } from '../utility/utility';
import { selfSchedulingTimeUnits, lengthValues, defaultLengthData } from '../config/constants/selfScheduling';

class IncidentsService {
  static USER_NOT_AVAILABLE = 'USER_NOT_AVAILABLE';

  static PATIENT_HAS_OVERLAPS = 'PATIENT_HAS_OVERLAPS';

  static ASSET_HAS_OVERLAPS = 'ASSET_HAS_OVERLAPS';

  static USER_HAS_OVERLAPS = 'USER_HAS_OVERLAPS';

  static ASSET_NOT_AVAILABLE = 'ASSET_NOT_AVAILABLE';

  static USER_CANNOT_PERFORM_SERVICE = 'USER_CANNOT_PERFORM_SERVICE';

  static USER_NOT_ASSIGNED_TO_HEADQUARTER = 'USER_NOT_ASSIGNED_TO_HEADQUARTER';

  static SERVICE_TYPE_NOT_ASSIGNED_TO_HEADQUARTER = 'SERVICE_TYPE_NOT_ASSIGNED_TO_HEADQUARTER';

  static async getAll(params) {
    const queryParams = {
      page: params.page ?? 1,
      search: params.search ?? '',
      customerId: params.customerId,
      finalCustomerId: params.finalCustomerId,
      serviceTypes: params.serviceTypes,
      assignedUser: params.assignedUser,
      startDate: params.startDate,
      endDate: params.endDate,
      status: params.status,
      statusList: params.statusList,
      excludedStatus: params.excludedStatus,
      creationType: params.creationType,
    };
    const { data } = await DataService.get(API.services.root, queryParams);
    return {
      services: data.services,
      total: data.total,
      pageSize: data.pageSize,
      page: data.page,
    };
  }

  static async getAllEventsarrings(search) {
    const queryParams = {
      status: [incidentsStatus.UNASSIGNED],
      search,
    };
    const { data } = await DataService.get(API.services.root, queryParams);
    return {
      services: data.services,
      total: data.total,
      pageSize: data.pageSize,
      page: data.page,
    };
  }

  static async getDatesAvailability({ idTypeService, idUser, idFinalCustomer, dates }) {
    const queryParams = {
      idTypeService,
      idUser,
      idFinalCustomer,
      dates,
    };
    const { data } = await DataService.get(API.services.root + API.services.datesAvailability, queryParams);
    return {
      datesAvailability: data.datesAvailability,
    };
  }

  static async getAllByDate({
    startDate,
    endDate,
    page = 1,
    serviceTypes,
    assignedUsers,
    excludedStatus,
    headquarter,
  }) {
    // const startDate = getDefaultDate(date, 0).startOf('start').toISOString();
    // const endDate = getDefaultDate(date, 23).endOf('hour').toISOString();
    const params = {
      startDate,
      endDate,
      page,
      serviceTypes,
      assignedUsers,
      excludedStatus,
      idHeadquarter: headquarter,
    };
    const { data } = await DataService.get(API.services.root, params);
    return data;
  }

  static async createService(serviceData) {
    const bodyData = {
      start: serviceData.start,
      idTypeService: serviceData.idTypeService,
      idAsset: serviceData.idAsset,
      idAssignedCompany: serviceData.idAssignedCompany,
      idContactWhoCalls: serviceData.idContactWhoCalls,
      name: serviceData.name,
      title: serviceData.title,
      reference: serviceData.reference,
      idFinalCustomer: serviceData.idFinalCustomer,
      servicePriority: serviceData.servicePriority,
      address: serviceData.address,
      customInfo: serviceData.customInfo,
      idHeadquarter: serviceData.idHeadquarter,
      repeatDates: serviceData.repeatDates,
      ...(serviceData.idUser && { idUser: serviceData.idUser }),
    };
    const { data } = await DataService.post(API.services.root, bodyData);
    return data.incident;
  }

  static async updateService(id, serviceData) {
    const updateData = {
      start: serviceData.start,
      idTypeService: serviceData.idTypeService,
      idAssignedCompany: serviceData.idAssignedCompany,
      idContactWhoCalls: serviceData.idContactWhoCalls,
      idAsset: serviceData.idAsset,
      idUser: serviceData.idUser,
      name: serviceData.name,
      title: serviceData.title,
      reference: serviceData.reference,
      servicePriority: serviceData.servicePriority,
      idFinalCustomer: serviceData.idFinalCustomer,
      address: serviceData.address,
      idHeadquarter: serviceData.idHeadquarter,
      customInfo: serviceData.customInfo,
      confirmedByUser: serviceData.confirmedByUser,
    };
    const { data } = await DataService.patch(API.services.root + id, updateData);
    return data.incident;
  }

  static async getById(id) {
    const { data } = await DataService.get(API.services.root + id);
    return data.service;
  }

  static async remove(id) {
    const { data } = await DataService.delete(API.services.root + id);
    return data.service;
  }

  static async cancel(id, cancellationReasson) {
    const { data } = await DataService.post(`${API.services.root + id}/status/`, {
      status: incidentsStatus.CANCELED,
      cancellationReasson,
    });
    return data.service;
  }

  static async finish(id) {
    const { data } = await DataService.post(`${API.services.root + id}/status/`, { status: incidentsStatus.FINISH });
    return data.service;
  }

  static async getCalendarEvents({
    startDate: startDateParam,
    endDate: endDateParam,
    page = 1,
    baseResources = {},
    baseMinDate,
    baseMaxDate,
    serviceTypes,
    assignedUsers,
    headquarter,
  }) {
    const excludedStatus = [incidentsStatus.CANCELED, incidentsStatus.UNASSIGNED];
    const data = await IncidentsService.getAllByDate({
      startDate: startDateParam,
      endDate: endDateParam,
      page,
      serviceTypes,
      assignedUsers,
      excludedStatus,
      headquarter,
    });
    let minDate = baseMinDate;
    let maxDate = baseMaxDate;
    const events = [];
    const resources = {
      assignedUsers: { key: 'assignedUserId', data: baseResources.assignedUsers ?? new Map() },
      serviceTypes: { key: 'serviceTypeId', data: baseResources.serviceTypes ?? new Map() },
    };
    const timeUnitsMap = new Map(Object.values(selfSchedulingTimeUnits).map((unit) => [unit.value, unit.label]));
    const lengthValuesMap = new Map(Object.values(lengthValues).map((item) => [item.value, item]));
    const lengthDataMap = new Map();
    const getEndDate = (serviceType, idUser, date) => {
      const { useCustomExecutionTime, lengthData, usersLengthData } = serviceType;
      const key = `${serviceType._id}-${idUser}`;
      const currentValue = lengthDataMap.get(key);
      if (currentValue) {
        return moment(date).add(currentValue.timeValue, currentValue.timeUnit);
      }
      let itemLengthData = null;
      if (useCustomExecutionTime) {
        itemLengthData =
          usersLengthData?.find((item) => item.usersIds.includes(idUser))?.lengthData ??
          usersLengthData?.[0]?.lengthData ??
          defaultLengthData;
      } else {
        itemLengthData = lengthData ?? defaultLengthData;
      }
      let timeUnitId = null;
      let timeValue = null;
      if (itemLengthData.type === lengthValues.CUSTOM.value) {
        timeUnitId = itemLengthData.timeUnit;
        timeValue = itemLengthData.timeValue;
      } else {
        const presetLengthValue = lengthValuesMap.get(itemLengthData.type);
        timeUnitId = presetLengthValue.timeUnit;
        timeValue = presetLengthValue.timeValue;
      }
      const timeUnit = timeUnitsMap.get(timeUnitId);
      lengthDataMap.set(key, {
        timeUnit,
        timeValue,
      });
      return moment(date).add(timeValue, timeUnit);
    };

    data.services.forEach((service) => {
      const description = service.title;
      const serviceTypeId = service.idTypeService;
      const assignedUserId = service.idUser;
      const startDate = moment(service.start);
      const endDate = getEndDate(service.infoServiceType, service.idUser, service.start);
      const eventData = {
        id: service._id,
        serviceTypeId,
        assignedUserId,
        title: description,
        start: startDate.toDate(),
        end: endDate.toDate(),
        data: service,
        assignedUser: service.infoAssignedUser?.profile?.name,
        assignedFinalCustomer: service.infoFinalCustomer?.fullname,
        assignedServiceType: service.infoServiceType.name,
        idColor: service.infoServiceType.idColor,
        privateId: service.privateId,
        confirmedByFinalCustomer: service.confirmedByFinalCustomer,
        confirmedByUser: service.confirmedByUser,
        isSelfScheduled: service.isSelfScheduled,
      };
      events.push(eventData);
      IncidentsService.addResource(
        resources.assignedUsers.data,
        assignedUserId,
        service.infoAssignedUser?.profile.name,
        service.infoAssignedUser,
      );
      IncidentsService.addResource(
        resources.serviceTypes.data,
        serviceTypeId,
        service.infoServiceType.name,
        service.infoServiceType,
      );
      minDate = IncidentsService.checkMinDate(minDate, startDate);
      maxDate = IncidentsService.checkMaxDate(maxDate, endDate);
    });
    if (!minDate) {
      minDate = getDefaultDate(startDateParam, 8);
    }
    if (!maxDate) {
      maxDate = getDefaultDate(endDateParam, 18);
    }
    return {
      total: data.total,
      pageSize: data.pageSize,
      events: events.sort((a, b) => (a.start < b.start ? -1 : 1)),
      resources,
      minDate,
      maxDate,
    };
  }

  static checkMinDate(date, startDate) {
    let minDate = date;
    const startTime = startDate.startOf('hour');
    if (!date || startTime.get('hours') < minDate.startOf('hour').get('hours')) {
      minDate = startTime;
    }
    return minDate;
  }

  static checkMaxDate(date, endDate) {
    let maxDate = date;
    const endTime = endDate.endOf('hour');
    if (!date || endTime.get('hours') >= maxDate.endOf('hour').get('hours')) {
      maxDate = endDate.endOf('hour');
    }
    return maxDate;
  }

  static addResource(resources, id, title, data) {
    const assignedResource = resources.get(id) ?? { id, title, data, totalServices: 0 };
    assignedResource.totalServices += 1;
    resources.set(id, assignedResource);
  }

  static async getIncidentsLogs(id) {
    const { data } = await DataService.get(`${API.services.root + id}/log`);
    return data.log;
  }

  static async verifyPendingEvents(search) {
    const queryParams = {
      status: [incidentsStatus.UNASSIGNED],
      search,
    };
    const { data } = await DataService.get(API.services.root + API.services.resultsVerification, queryParams);
    return data.hasResults;
  }
}

export { IncidentsService };
